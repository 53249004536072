import { ABANDONED } from '../config';
import { generateUniqueString } from '~/server/newTradingApi/utils';
import { isClient } from '~/utils/envHelper';
const newParams = {
  languageType: 0,
  sign: 'SIGN',
  timeZone: 'string',
  orderId: 'string'
};

// contract/updatePresetTPSL 修改预设止盈止损价格
const contractUpdatePresetTPSL = {
  productCode: 'cmt_ethusdt',
  bizNo: '1063691427059335113',
  type: 1,
  presetTakeProfitPrice: '2000.00',
  presetStopLossPrice: '1840',
  languageType: 1
};

const paramsKeyMap = {
  productCode: ABANDONED,
  bizNo: 'orderId',
  type: ABANDONED,
  presetTakeProfitPrice: 'updateOpenTpTriggerPrice',
  presetStopLossPrice: 'updateOpenSlTriggerPrice'
};
const updateOpenTpSlTriggerPriceAdapter = {
  req: data => {
    const params = {};
    if (isClient) {
      const paramsList = window.Object.entries(data);
      paramsList.forEach(([key, value]) => {
        switch (true) {
          case key === 'presetTakeProfitPrice': // 预设止赢
            if (value) {
              params.setOpenTp = true;
              params.openTpParam = {
                side: params.orderSide === 'BUY' ? 'SELL' : 'BUY', // 止盈方向 与 下单方向相反
                size: params.size,
                price: 0,
                clientOrderId: generateUniqueString(), // 64位
                triggerPrice: value,
                triggerPriceType: 'LAST_PRICE'
              };
            }
            break;
          case key === 'presetStopLossPrice': // 预设止损
            if (value) {
              params.setOpenSl = true;
              params.openSlParam = {
                side: params.orderSide === 'BUY' ? 'SELL' : 'BUY', // 止損方向 与 下单方向相反
                size: params.size,
                price: 0, // 下单止盈止损为市价单
                clientOrderId: generateUniqueString(), // 64位
                triggerPrice: value,
                triggerPriceType: 'LAST_PRICE'
              };
            }
            break;
          default:
            if (paramsKeyMap[key] !== ABANDONED) {
              params[paramsKeyMap[key] || key] = value;
            }
        }
      });
    }
    return params;
  },

  res: res => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }
};

export default updateOpenTpSlTriggerPriceAdapter;
