import { ABANDONED } from '../config';
import { isClient } from '~/utils/envHelper';
const newParams = {
  languageType: 0,
  sign: 'SIGN',
  timeZone: 'string',
  filterContractIdList: [0], // 过滤只取消对应合约的委托单。如果为空的话会取消所有合约委托单
  filterNotPositionTpsl: true // 暂时不使用
};

// contract/cancelAll 取消合约所有
const contractCancelAll = {
  type: 0,
  cancelCategories: [1, 2, 3, 4, 5, 6, 7, 8],
  productCode: 'cmt_ethusdt',
  languageType: 1
};

const paramsKeyMap = {
  type: ABANDONED,
  productCode: ABANDONED,
  cancelCategories: 'filterContractIdList'
};
const cancelAllOrderAdapter = {
  req: data => {
    const params = {};
    if (isClient) {
      const paramsList = window.Object.entries(data);
      paramsList.forEach(([key, value]) => {
        switch (true) {
          default:
            if (paramsKeyMap[key] !== ABANDONED) {
              params[paramsKeyMap[key] || key] = value;
            }
        }
      });
    }
    return params;
  },

  res: res => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }
};

export default cancelAllOrderAdapter;
