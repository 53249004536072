import { ABANDONED } from '../config';
import { isClient } from '~/utils/envHelper';
const newParams = {
  languageType: 0,
  sign: 'SIGN',
  timeZone: 'string',
  filterCoinId: [
    "string"
  ],
  filterContractId: [
    "string"
  ],
  filterType: [
    "UNKNOWN_COLLATERAL_TRANSACTION_TYPE"
  ],
  filterStartCreatedTimeInclusive: "string",
  filterEndCreatedTimeExclusive: "string"
};

const paramsKeyMap = {
  productCode: ABANDONED,
  orderNo: ABANDONED
};
const getFinancialConditionListAdapterAdapter = {
  // req: data => {
  //   const params = {};
  //   const paramsList = window.Object.entries(data);
  //   paramsList.forEach(([key, value]) => {
  //     switch (true) {
  //       case key === 'orderNo':
  //         params.orderIdList = Array.isArray(value) ? value : [value]; // 兼容传入 多个id 的数组情形
  //         break;
  //       default:
  //         if (paramsKeyMap[key] !== ABANDONED) {
  //           params[paramsKeyMap[key] || key] = value;
  //         }
  //     }
  //   });
  //   return params;
  // },

  req: data => {
    const params = {};
    if(isClient){
      const paramsList = window.Object.entries(data);
      paramsList.forEach(([key, value]) => {
        switch (true) {
          default:
            if (paramsKeyMap[key] !== ABANDONED) {
              params[paramsKeyMap[key] || key] = value;
            }
        }
      });
    }
    return params;
  },

  res: res => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }
};

export default getFinancialConditionListAdapterAdapter;
