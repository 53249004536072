import { numMultiply, toNumberOrBoolean } from '../../utils';
import { isClient } from '~/utils/envHelper';
const resKeyMap = {
  // oldKey => newKey
  productName: 'contractName',
  baseCoinId: 'baseCoinId',
  minLeverage: 'displayMinLeverage',
  maxLeverage: 'displayMaxLeverage',
  settleTime: 'fundingDailySettleTime',
  webMarginScale: 'displayWebLeverageSettingList',
  appMarginScale: 'displayAppLeverageSettingList',

  productCode: 'extraConfig.productCode',
  iconUrl: 'extraConfig.iconUrl',
  preValue: 'extraConfig.preValue',
  forwardContractFlag: 'extraConfig.forwardContractFlag', // 是否是正向合约
  status: 'extraConfig.status', // 0 下架, 1 正常, 2 临时维护,
  indexDataSource: 'extraConfig.indexDataSource',
  contractSort: 'extraConfig.contractSort',
  simulation: 'extraConfig.simulation', // 是否是模拟盘 todo Boolean

  quoteCoinId: 'quoteCoinId',
  placeConfig: 'placeConfig', // 内容为object
  priceUseFeeRate: 'priceUseFeeRate', // 计算风险价格/保证金率是否使用手续费率
  newP: 'displayNew',
  hotP: 'displayHot',
  buyLimitPriceRatio: 'buyLimitPriceRatio', // 买限价比例
  sellLimitPriceRatio: 'sellLimitPriceRatio',
  openCostUpRatio: 'frozenMarginFloatingRatio', // 开仓成本上浮比例
  openCostFeeUpRatio: 'frozenFillFeeFloatingRatio', // 开仓手续费上浮比例
  longLimitBurstPrice: 'longLimitLiquidatePrice', // 多仓最低限制爆仓价
  shortLimitBurstPrice: 'shortLimitLiquidatePrice', // 空仓最高限制爆仓价
  minTradingUnit: 'stepSize', // 最小交易单位
  trackingSupport: 'supportTracing', // 是否支持跟单
  planMarketPrice: 'supportPlanMarket',

  // 需要联合数据处理
  pricedSymbol: '', // USDT
  baseSymbol: '', // BTC
  quoteCoinSymbol: '', // USDT 保证金币种符号
  quoteSymbol: '', // USDT 保证金币种符号
  feeRate: 'feeRate', // 手续费率 max(takerFeeRate, makerFeeRate)
  dailySettleTimes: '', // 每日结算次数 fundingDailySettleTime.length
  marketPriceOpenLimitSize: '', // todo marketOpenLimitSize / stepSize
  marketPriceCloseLimitSize: '', // todo marketCloseLimitSize / stepSize
  // splitMargin: '', // supportSeparatedModeList.isEmpty() || supportSeparatedModeList.contains(SeparetedMode.Se)
  supportSharedMode: '', // 是否支持全仓模式  supportMarginModeList.isEmpty() || supportMarginModeList.contains("SHARED")
  supportCrossMode: '', // 是否支持全仓模式  supportMarginModeList.isEmpty() || supportMarginModeList.contains("CROSS")
  supportIsolatedMode: '', // 是否支持逐仓模式   supportMarginModeList.isEmpty() || supportMarginModeList.contains("ISOLATED")
  supportCombinedMode: '', // 是否支持合仓模式   supportMarginModeList.isEmpty() || supportSeparatedModeList.contains("COMBINED")
  supportSeparatedMode: '', // 是否支持分仓模式   supportMarginModeList.isEmpty() || supportSeparatedModeList.contains("SEPARATED")
  // 待确认
  limitPriceFlag: '', // 1 合理标记价格 2 使用最新成交价
  priceEndStep: '', // 价格最后一位步长
  autoAppendMargin: '', // 是否支持自动追加保证金
  priceUseCapitalRate: '', // 计算减仓价格是否使用资金费率
  warningCoefficient: '', // 预警系数
  reduceChangePriceRatio: '', // 未使用 减仓追价比例

  // 公式新增
  contractId: 'contractId',
  tickSize: 'tickSize',
  riskLimitTierList: 'riskLimitTierList',
  quoteCoinStepSize: '', // 公式新增，但需要联合币对处理
  enableDisplay: 'enableDisplay',
  takerFeeRate: 'takerFeeRate',
  makerFeeRate: 'makerFeeRate',
  frozenMarginFloatingRatio: 'frozenMarginFloatingRatio',
  frozenFillFeeFloatingRatio: 'frozenFillFeeFloatingRatio',
  markPrice: 'markPrice'
};

const contractListAdapter = {
  req: () => {
    const params = {
      languageType: 1,
      sign: 'SIGN',
      timeZone: 'string'
    };
    return { ...params };
  },

  res: res => {
    return new Promise((resolve, reject) => {
      const {
        code,
        data: { coinList, contractList },
        responseTime
      } = res;

      const tempContractList = [];
      if (isClient) {
        const resKeyEntryList = window.Object.entries(resKeyMap);
        contractList.forEach(contract => {
          const newContract = {};
          const quoteSymbolInfo = coinList.find(coin => coin.coinId === contract.quoteCoinId);
          const baseSymbolInfo = coinList.find(coin => coin.coinId === contract.baseCoinId);

          resKeyEntryList.forEach(([key, value]) => {
            switch (true) {
              case key === 'pricedSymbol':
                newContract.pricedSymbol = quoteSymbolInfo.coinName;
                break;
              case key === 'quoteCoinSymbol':
                newContract.quoteCoinSymbol = quoteSymbolInfo.coinName;
                break;
              case key === 'quoteCoinStepSize':
                newContract.quoteCoinStepSize = quoteSymbolInfo.stepSize;
                break;
              case key === 'quoteSymbol':
                newContract.quoteSymbol = quoteSymbolInfo.coinName;
                break;
              case key === 'baseSymbol':
                newContract.baseSymbol = baseSymbolInfo.coinName;
                break;
              case key === 'feeRate': // 手续费率 max(takerFeeRate, makerFeeRate)
                newContract.feeRate = Math.max(Number(contract.takerFeeRate), Number(contract.makerFeeRate));
                break;
              case key === 'dailySettleTimes':
                newContract.dailySettleTimes = contract.fundingDailySettleTime.length;
                break;
              case key === 'marketPriceOpenLimitSize':
                //newContract.marketOpenLimitSize = numMultiply(contract.marketOpenLimitSize, 1 / contract.stepSize);
                newContract.marketOpenLimitSize = contract.marketOpenLimitSize;
                break;
              case key === 'marketPriceCloseLimitSize':
                //newContract.marketPriceCloseLimitSize = numMultiply(contract.marketCloseLimitSize, 1 / contract.stepSize);
                newContract.marketPriceCloseLimitSize = contract.marketCloseLimitSize;
                break;
              // case key === 'splitMargin': // supportSeparatedModeList.isEmpty() || supportSeparatedModeList.contains('SEPARATED')
              //   newContract.splitMargin =
              //     !contract.supportSeparatedModeList.length ||
              //     contract.supportSeparatedModeList.includes('SEPARATED');
              //   break;
              case key === 'supportSharedMode': // supportMarginModeList.isEmpty() || supportMarginModeList.contains('CROSS') || supportMarginModeList.contains('SHARED')
                newContract.supportSharedMode =
                  !contract.supportMarginModeList.length ||
                  contract.supportMarginModeList.includes('SHARED') ||
                  contract.supportMarginModeList.includes('CROSS');
                break;
              case key === 'supportCrossMode': // supportMarginModeList.isEmpty() || supportMarginModeList.contains('CROSS') || supportMarginModeList.contains('SHARED')
                newContract.supportCrossMode =
                  !contract.supportMarginModeList.length ||
                  contract.supportMarginModeList.includes('SHARED') ||
                  contract.supportMarginModeList.includes('CROSS');
                break;
              case key === 'supportIsolatedMode': // supportMarginModeList.contains("ISOLATED")
                newContract.supportIsolatedMode =
                  !contract.supportMarginModeList.length || contract.supportMarginModeList.includes('ISOLATED');
                break;
              case key === 'supportCombinedMode': // supportSeparatedModeList.isEmpty() || supportSeparatedModeList.contains("ISOLATED")
                newContract.supportCombinedMode =
                  !contract.supportSeparatedModeList.length || contract.supportSeparatedModeList.includes('COMBINED');
                break;
              case key === 'supportSeparatedMode': // supportSeparatedModeList.contains("SEPARATED")
                newContract.supportSeparatedMode =
                  !contract.supportSeparatedModeList.length || contract.supportSeparatedModeList.includes('SEPARATED');
                break;
              case key === 'markPrice':
                newContract.markPrice = '';
                break;
              default:
                if (value) {
                  // 已明确列举新 旧字段名 对应
                  // 使用'.'分割key 'extraConfig.simulation' -> ['extraConfig', 'simulation'] -> contract['extraConfig']['simulation']
                  const keyNameList = value.split('.');
                  newContract[key] =
                    keyNameList.length === 1 ? contract[keyNameList[0]] : contract[keyNameList[0]][keyNameList[1]];
                }
            }
            if (value) {
              // 已明确列举新 旧字段名 对应
              newContract[key] = toNumberOrBoolean(newContract[key]);
            }
          });
          newContract.stepSize = contract.stepSize;
          tempContractList.push(newContract);
        });
      }
      // console.log('tempContractList: ', tempContractList)
      const result = {
        code,
        data: { coinList, contractList: tempContractList },
        restime: responseTime
      };

      resolve(result);
    });
  }
};

export default contractListAdapter;
