// import { numMultiply, toNumberOrBoolean } from '../../utils';

// "language": "en_US",
// "sign": "SIGN",
// "timeZone": "string",
// "positionId": "400001061726716740",
// "reversePositionSize": "0.123",
// "cancelCloseOpenOrder": true

const reversePositionAdapter = {
  req: data => {
    const params = {
      languageType: 1,
      language: 'en_US',
      sign: 'SIGN',
      timeZone: 'string',
      orderSource: 'WEB'
    };
    console.log('reversePositionAdapter data: ', data)
    return { ...params, ...data };
  },

  // res: res => {
  //   return new Promise((resolve, reject) => {
  //     const {
  //       code,
  //       data,
  //       responseTime,
  //       msg
  //     } = res;
  //     const result = {
  //       code,
  //       data,
  //       restime: responseTime,
  //       msg
  //     };

  //     resolve(result);
  //   });
  // }

  res: res => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }
};

export default reversePositionAdapter;
