// 前端 统一 server 端服务封装

import { cacheApiFunc } from '~/utils/cacheApi/index';
import { getInstance } from '~/utils/doRequest';

const commonInstance = getInstance('');


// 社群配置
export const communityConfig = data => {
  return commonInstance.get('/v1/user/official/getCommunityUrl', data);
};

// 首页- banner 和公告
// export const topBanner = cacheApiFunc(data => {
//   return commonInstance.post('/v1/if/index/banner', data);
// });
export const topBanner = data => {
  return commonInstance.post('/v1/if/index/banner', data);
};

// 首页- MainBanner 和 navigation bar
// export const getMainBanner = cacheApiFunc(data => {
//   return commonInstance.post('/v1/mix/index/mainBanner', data);
// });
export const getMainBanner = data => {
  return commonInstance.post('/v1/mix/index/mainBanner', data);
};

// 首页- MainBanner
// export const getNavigationBar = cacheApiFunc(data => {
//   return commonInstance.post('/v1/mix/index/navigationBar', data);
// });
export const getNavigationBar = data => {
  return commonInstance.post('/v1/mix/index/navigationBar', data);
};

// @note 首页- popup api待替換
// export const getPopUpData = cacheApiFunc(data => {
//   return commonInstance.post('/v1/user/public/getFloatingWindowConfig/3', data);
// });
export const getPopUpData = data => {
  return commonInstance.post('/v1/user/public/getFloatingWindowConfig/3', data);
};

// 首页-置顶交易对
export const allExchange = data => {
  return commonInstance.post('/v1/if/v2/index/allExchange', data);
};

// 同步自选交易对
export const syncSelfExchangeType = data => {
  return commonInstance.post('/v1/if/base/syncSelfExchangeType', data);
};

// 费率列表
export const feeRateList = data => {
  return commonInstance.post('/v1/if/base/feeRateList', data)
}

// 验证是否需要弹出验证码框
export const validateConfig = () => {
  return commonInstance.post('/v1/mix/public/config')
}

// 合约费率列表
export const contractRateList = data => {
  return commonInstance.post('/v1/mix/public/contractRateConfig', data);
};

// 混合合约费率列表

export const mcpContractRateList = data => {
  return commonInstance.post('/v1/mix/public/professionalContractRateConfig', data);
};

// export const getCoinList = cacheApiFunc(data => {
//   return commonInstance.post('/v1/if/base/coinList', data);
// });
export const getCoinList = data => {
  return commonInstance.post('/v1/if/base/coinList', data);
};

// 左右币列表
export const leftAndRightCoinList = data => {
  return commonInstance.post('/v1/if/base/coinList', data);
};

// 国际化区号
export const areaCodeList = data => {
  return commonInstance.post('/v1/if/base/areaCodeList', data);
};

// 国际化区号
export const getAreaCodeList = data => {
  //return commonInstance.post('/v1/msg/areaCode/app/list', data)
  return commonInstance.get('/v1/msg/areaCode/app/v2/list', data);
};

export const checkServiceSupport = data => {
  return commonInstance.post('/v1/user/public/getIpNationalityLimit', data);
};

// 币种介绍
export const coinLink = data => {
  // return commonInstance.post('/v1/if/base/getCoinLink', data)
  return commonInstance.get('/v1/if/base/v2/getCoinLink', data);
};
// 停服判断
export const stopService = data => {
  return commonInstance.post('/v1/if/system/stopService', data);
};

/**
 * 获取合约列表
 * @param data
 * @return {*}
 */
// export const getContractList = cacheApiFunc(data => {
//   if (location.href.includes('rc')) {
//     data = {
//       currentEnv: 'beta'
//     };
//   }
//   return commonInstance.get('/v1/if/base/v2/contractList', data);
// });
export const getContractList = data => {
  if (location.href.includes('rc')) {
    data = {
      currentEnv: 'beta'
    };
  }
  return commonInstance.get('/v1/if/base/v2/contractList', data);
};
/**
 * 开通合约账户
 * @param {*} data
 */
export const openContract = data => {
  return commonInstance.post('/v1/if/base/openContract', data);
};

export const getBigFamilyQrImg = data => {
  return commonInstance.post('/v1/if/base/getBigFamilyQrImg', data);
};

/****
 * 发送短信验证码（login）
 */
export const userSecuritySendMobile = data => {
  return commonInstance.post('/v1/user/security/send-mobile', data);
};

/****
 * 发送邮箱验证码（login）
 */
export const userSecuritySendEmail = data => {
  return commonInstance.post('/v1/user/security/send-email', data);
};

/****
 * 验证推薦碼(VIP code)是否存在
 */
export const getChannelCodeFromVipCode = data => {
  return commonInstance.get('/v1/user/public/getChannelcodeFromVipcode', data);
};

/****
 * 验证登录名是否存在
 */
export const userSecurityPublicCheckLoginName = data => {
  return commonInstance.post('/v1/user/public/check-login-name', data);
};

/****
 * 获取国家列表
 */
export const userPublicAreaList = data => {
  return commonInstance.post('/v1/user/public/areaList', data);
};

// 网易云盾开关
export const cloudValidateSwitch = data => {
  return commonInstance.post('/v1/mix/public/webCloudValidate', data);
};

export const withdrawRateList = data => {
  return commonInstance.post('/v1/mix/public/webWithdrawFee', data);
};

// 获取用户身份信息  是否是交易员
// export const getUserIdentity = data => {
//   return commonInstance.post('/v1/if/base/trace/identity', data)
// }

// 获取用户身份信息  是否是交易员
export const getTraceUserInfoNew = data => {
  return commonInstance.post('/v1/trace/user/getTraceUserInfo', data);
};

// pagestatus 的接口
export const systemAssetsStatus = data => {
  return commonInstance.post('/v1/if/index/assetInfos', data);
};
/***
 * 跟单配置信息
 */
export const traceSystemConfig = data => {
  return commonInstance.post('/v1/trace/public/globalConfig', data);
};

// 发送验证码新接口 2021-01-04
export const verifyCodeSend = data => {
  // data.bizType = 'LOGIN_PWD_MODIFY_MAIL';
  // return commonInstance.post('/v1/msg/verifyCode/send', data)
  return commonInstance.post(
    data.sendType === 'SMS' ? '/v1/user/security/send-mobile' : '/v1/user/security/send-email',
    data
  );
};

// 防刷机制后发送验证码新接口 2021-12-06
export const verifyCodeSendNew = data => {
  return commonInstance.post('/v1/msg/verifyCode/send/validate', data);
};

// 手机注册
export const verifyCodeSendPhone = data => {
  return commonInstance.post('/v1/user/public/send-mobile/validate', data);
};

// 邮箱注册
export const verifyCodeSendEmail = data => {
  return commonInstance.post('/v1/user/public/send-email/validate', data);
};

/****
 * 二次验证防刷机制后发送email验证码接口
 */
export const sendEmailVerifyCode = data => {
  return commonInstance.post('/v1/user/public/send-email/validate', data);
};

/****
 * 二次验证防刷机制后发送mobile验证码接口
 */
export const sendMobileVerifyCode = data => {
  return commonInstance.post('/v1/user/public/send-mobile/validate', data);
};

// usdtrate
// export const usdtRate = cacheApiFunc(data => {
//   return commonInstance.post('/v1/user/public/usdtRate', data);
// });
export const usdtRate = data => {
  return commonInstance.post('/v1/user/public/usdtRate', data);
};

// ----------------

/**
 * 获取node 系统时间
 * @param {} data
 */
export const getCurrentTime = data => {
  return new Promise(resolve => {
    resolve(Date.now());
  });
};

// 新增首页行情接口
// 2024-06-03: 已不在首页使用，改用websocket取用行情
export const indexHomeQuotation = data => {
  return commonInstance.post('/v1/mix/index/homeQuotation', data);
};

// 新增首页行情接口(合约Pro)
// 2024-06-03: 已不在首页使用，改用websocket取用行情
export const indexQuote = data => {
  return commonInstance.post('/v1/mix/index/getTicker', data);
};

/**
 * 须登录：查询用户自选
 * 查询参数：businessLine:1币币，10专业合约，11混合合约
 * @param data
 * @returns {Promise<{}>}
 */
export const indexUserSelectedList = data => {
  return commonInstance.post('/v1/mix/index/userSelectedList', data);
};

/**
 * 须登录：添加用户自选
 * 参数：businessLine:1币币，10专业合约，11混合合约
 * symbolId
 * @param data
 * @returns {Promise<{}>}
 */
export const addUserSelf = data => {
  return commonInstance.post('/v1/user/optional/addUserSelf', data);
};

/**
 * 须登录：移除用户自选
 * @param data
 * @returns {Promise<{}>}
 */
export const deleteUserSelf = data => {
  return commonInstance.post('/v1/user/optional/deleteUserSelf', data);
};

/**
 * 须登录：查询合約Pro用户自选
 * 查询参数：exchangeType:1:币币，2:U合约，3:币本位合约，4:混合合约，5:其他合约，6:合约pro U合约
 * @param data
 * @returns {Promise<{}>}
 */
export const getUserSelfList = data => {
  return commonInstance.post('/v1/user/optional/getUserSelfList', data);
};

/**
 * 须登录：合約Pro 添加/移除 用户自选
 * exchangeType: 1:币币，2:U合约，3:币本位合约，4:混合合约，5:其他合约，6:合约pro U合约
 * optionalType: 1:添加，2:删除
 * symbolId: contractId
 * @param data
 * @returns {Promise<{}>}
 */
export const operationUserSelfOptional = data => {
  return commonInstance.post('/v1/user/optional/operationUserSelfOptional', data);
};

/**
 * 获取用户偏好设置
 * @returns {Promise<{}>}
 */
export const getUserPreference = () => {
  return commonInstance.post('/v1/user/preference/get');
};

/**
 * 设置用户偏好设置
 * @param data
 * @returns {Promise<{}>}
 */
export const setUserPreference = data => {
  return commonInstance.post('/v1/user/preference/set', data);
};

/**
 * 获取数量包含已委托的配置
 * @returns {Promise<{}>}
 */
export const getDelegateFlag = () => {
  return commonInstance.post('/v1/trigger/config/delegate/flag/get');
};

/**
 * 修改
 * @param data
 * @returns {Promise<{}>}
 */
export const setDelegateFlag = data => {
  return commonInstance.post('/v1/trigger/config/delegate/flag/set', data);
};
/****
 * 重要通知
 *
 */
export const getImportNotice = data => {
  return commonInstance.get('/v1/mix/index/importance', data);
};

/****
 * 提幣成功/充幣成功/合夥人站內信通知
 *
 */
export const getSystemNotice = data => {
  // return commonInstance.post('/v1/if/push/list', data)
  return commonInstance.post('/v1/if/push/v2/list', data);
};

/****
 * 系统通知标记未已读
 *
 */
export const readPushMsg = data => {
  return commonInstance.post('/v1/if/push/readPushMsg', data);
};

/****
 * 查询海外短信状态
 */
export const overseasSmsStatus = () => {
  return commonInstance.post('/v1/msg/hx/app/query');
};

/****
 * 获取登陆注册左侧轮播图
 */
export const getPictureList = data => {
  const url = `/v1/mix/public/getPictureList?language=${data.language}&type=${data.type}&vipCode=${data.vipCode}`;
  return commonInstance.get(url);
};

// 获取未读接口
export const getPushCount = cacheApiFunc(() => {
  return commonInstance.post('/v1/if/push/count');
}, {interval: 500});
// 获取站内信息详情

export const getMsgDetail = data => {
  return commonInstance.post('/v1/if/push/detail', data);
};

// 获取热门币对
export const getHotTicker = data => {
  return commonInstance.post('/v1/mix/index/getTicker', data);
};

// 根据短链获取长链接
export const getLongUrl = (shortUrl) => {
  return commonInstance.post(
    "/v1/short_url/long_url?shortUrl="+shortUrl,
    {}
  );
};

// 获取代理统计信息
export const getAgentStatistics = data => {
  return commonInstance.post('/v1/mix/agent/statistics', data);
};

// 获取代理邀请列表
export const getInvitationList = data => {
  return commonInstance.post('/v1/mix/agent/invitation_list?page='+data.page+'&size='+data.size);
};

// 获取代理信息
export const getIsAgent = cacheApiFunc(data => {
  return commonInstance.get('/v1/mix/agent/is_agent', data);
});

// 根据长链获取短链
export const getShortUrl = data => {
  return commonInstance.post("/v1/short_url/generate", data);
};

// 上传身份证
export const uploadIdentityPicNotBase = 'v1/user/public/uploadIdentityPicNotBase';


//  获取热门活动
// export const getPopularActivity = cacheApiFunc(data => {
//   return commonInstance.get('/v1/activity/getPopularActivityList', data);
// });
export const getPopularActivity = data => {
  return commonInstance.get('/v1/activity/getPopularActivityList', data);
};
// 根据长链获取短链
// export const getContractActiveRank = cacheApiFunc(data => {
//   return commonInstance.post("v1/mix/public/contract/getContractActiveRank", data);
// });
export const getContractActiveRank = data => {
  return commonInstance.post("v1/mix/public/contract/getContractActiveRank", data);
};
