import { ABANDONED } from '../config';
import { generateUniqueString, numMultiply } from '~/server/newTradingApi/utils';
import { isClient } from '~/utils/envHelper';
const newParams = {
  languageType: 0,
  sign: 'string',
  timeZone: 'string',
  contractId: '10000001',
  marginMode: 'SHARED', // 保证金模式 SHARED, CROSS, ISOLATED
  separatedMode: 'COMBINED', // 分仓模式 COMBINED, SEPARATED
  separatedOpenOrderId: '0', // 分仓仓位创建委托单id，当 separated_mode=SEPARATED 时，如果为0则视为开仓分仓单，如果不为0则视为对应分仓的操作单(可开可平)
  positionSide: '', // 仓位方向 (用于双向持仓功能) LONG, SHORT
  orderSide: 'BUY', // 买卖方向 BUY, SELL
  price: '10000.0',
  size: '0.1',
  leverage: '0',
  clientOrderId: '123', // 64位
  type: 'LIMIT', // LIMIT, MARKET, STOP_LIMIT, STOP_MARKET, TAKE_PROFIT_LIMIT, TAKE_PROFIT_MARKET
  timeInForce: 'GOOD_TIL_CANCEL', // 委托单执行策略。type为 LIMIT/STOP_LIMIT/TAKE_PROFIT_LIMIT 时有意义。此字段必填，市价单必为IMMEDIATE_OR_CANCEL. GOOD_TIL_CANCEL, FILL_OR_KILL, IMMEDIATE_OR_CANCEL, POST_ONLY
  reduceOnly: false, // 是否是只减仓委托。此字段必填
  triggerPrice: 'string',
  triggerPriceType: 'UNKNOWN_PRICE_TYPE', // LAST_PRICE, INDEX_PRICE, MARK_PRICE, ASK1_PRICE, BID1_PRICE
  extraType: '',
  extraDataJson: '',
  positionTpsl: false, // 仓位增加止盈止损
  setOpenTp: false, // 下单时增加止赢
  setOpenSl: false, // 下单时增加止赢
  openTpParam: {
    side: '',
    price: '', // 下单止盈止损为市价单
    size: '',
    clientOrderId: '', // 64位
    triggerPrice: '',
    triggerPriceType: '' // LAST_PRICE, INDEX_PRICE, MARK_PRICE, ASK1_PRICE, BID1_PRICE
  },
  openSlParam: {
    side: '',
    price: '',
    size: '',
    clientOrderId: '', // 64位
    triggerPrice: '',
    triggerPriceType: '' // LAST_PRICE, INDEX_PRICE, MARK_PRICE, ASK1_PRICE, BID1_PRICE
  }
};
// 1、contract/v2/place 合约下单
const contractV2Place = {
  productCode: '', // todo 产品编码
  amount: '', // todo 合约张数，页面可以切换张/数量/U，此处如何兼容
  matchType: 0, // 匹配类型 0:限价 1：市价
  type: '', // 类型 1开仓 2平仓
  side: '', // 方向 1多仓 2空仓
  timeInForce: '', // 有效方式 0普通GTC,1 postonly,2 fok, 3 ioc 如果不传，代表普通单
  tradePrice: '', // 交易价格
  presetStopLossPrice: '', // 预设止损价格
  presetTakeProfitPrice: '' // 预设止盈价格
};

// 2、contract/placePlan 计划委托下单
const contractPlacePlan = {
  productCode: '',
  amount: '', // 合约张数
  matchType: 0, // 匹配类型 0:限价 1：市价
  type: '', // 类型 1开仓 2平仓
  side: '', // 方向 1多仓 2空仓
  triggerPrice: '',
  triggerType: '', // 触发类型 1代表基于成交价触发 2代表基于合理标记价格触发 如果不存在，默认值是1
  timeInForce: '', // 有效方式 0普通GTC,1 postOnly,2 fok, 3 ioc 如果不传，代表普通单
  tradePrice: '', // 交易价格
  presetStopLossPrice: '', // 预设止损价格
  presetTakeProfitPrice: '', // 预设止盈价格
  executePrice: '27800' // 计划限价执行价格
};

// 3、contract/newStopPlan 止盈止损单下单
const contractNewStopPlan = {
  productCode: '',
  side: '', // 1多仓，2空仓
  stopPrice: '', // 止盈止损价格,
  planType: '1', //  1止盈 2止损
  delegateCount: '', // 委托量
  matchType: '0', // 匹配类型 0:限价 1：市价
  triggerType: '', // 触发类型 1代表基于成交价触发 2代表基于合理标记价格触发 如果不存在，默认值是1
  executePrice: '' // 止赢止损自定义限价
};

const paramsKeyMap = {
  // productCode: 'contractId',
  marginMode: 'marginMode', // 新增 保证金模式 SHARED, CROSS, ISOLATED
  separatedMode: 'separatedMode', // 新增 分仓模式 COMBINED, SEPARATED
  separatedOpenOrderId: 'separatedOpenOrderId', // 新增 分仓仓位创建委托单id，当 separatedMode=SEPARATED 时，如果为0则视为开仓分仓单，如果不为0则视为对应分仓的操作单(可开可平)
  positionSide: 'positionSide', // 新增 仓位方向 (用于双向持仓功能) LONG, SHORT
  stepSize: ABANDONED, // 新增 用于将张数 转换为 币的数量，后端只支持币的数量
  orderType: ABANDONED, // 新增 由于三个下单接口合为一个，无法通过现有3个接口的参数判断下单类型，需要新增该字段
  priceByTriggerType: ABANDONED, // 新增 当上面orderType 为 PLAN_MARKET 或 PLAN_LIMIT时，需要传入该参数，以确定 计划委托 触发价格 方向，并转换为后端可接受字段 triggerType 为 markPrice 传 最新标记价格；triggerType 为 lastPrice，则传入最新价。

  matchType: ABANDONED,
  side: ABANDONED,
  type: ABANDONED,
  amount: ABANDONED,
  tradePrice: ABANDONED,
  executePrice: ABANDONED,
  timeInForce: ABANDONED,

  planType: ABANDONED,
  stopPrice: ABANDONED,
  triggerType: ABANDONED,
  delegateCount: ABANDONED
};

/**
 * 获取触发价格类型  1代表基于成交价触发 2代表基于合理标记价格触发 如果不存在，默认值是1
 * @param typeNum
 * @returns {string}
 */
function getTriggerPriceType(typeNum) {
  return Number(typeNum) ? (Number(typeNum) === 1 ? 'LAST_PRICE' : 'MARK_PRICE') : 'LAST_PRICE';
}

const createOrderAdapter = {
  req: data => {
    const { stepSize = 0.1, orderType } = data; // 取出 币对stepSize, stepSize * amount = 币对数量
    let params = {};
    if (isClient) {
      const paramsList = window.Object.entries(data);
      // 交易随机64位字符串
      params.clientOrderId = generateUniqueString();
      // 设置 reduceOnly
      params.reduceOnly = false; // 界面无此功能，但仓位止赢止损时 需要 设置为true
      // 确定订单数量
      // params.size = numMultiply(Number(data.amount) || Number(data.delegateCount), Number(stepSize));
      // params.size = numMultiply(Number(data.amount), Number(stepSize));
      params.size = Number(data.amount);
      params.orderSide = data.orderSide;
      // params.orderSide = Number(data.side) === 1 ? 'BUY' : 'SELL';
      // if(data.listSide){
      //   params.orderSide = Number(data.side) === 1 ? 'BUY' : 'SELL';
      // }

      // 确定后端支持的订单类型 (LIMIT, MARKET, STOP_LIMIT, STOP_MARKET, TAKE_PROFIT_LIMIT, TAKE_PROFIT_MARKET)
      /**
     ** 解析data.orderType **
     orderType支持类型：
     普通委托 -> LIMIT,
     闪电委托 -> MARKET
     计划委托闪电开仓 -> PLAN_MARKET
     计划委托执行价格 -> PLAN_LIMIT
     仓位止赢止损 -> POSITION_TP_SL
     */
      const orderTypeEnum = [
        'LIMIT',
        'MARKET',
        'PLAN_LIMIT',
        'PLAN_MARKET',
        'POSITION_TP_SL',
        'TAKE_PROFIT_LIMIT',
        'TAKE_PROFIT_MARKET',
        'STOP_MARKET',
        'STOP_LIMIT'
      ];
      if (!orderTypeEnum.includes(orderType)) {
        // 没有填入规定的订单类型、直接报错，统一规范后 删掉该代码
        console.error('请填入正确的订单类型字段：orderType');
        return;
      }
      // console.log("orderType:",orderType)
      // console.log("data:",data)
      switch (orderType) {
        case 'PLAN_LIMIT':
        case 'PLAN_MARKET':
          console.log('final params41: ', orderType);
          console.log('final params42: ', data.priceByTriggerType);
          if (data.priceByTriggerType === undefined) {
            // 没有传入约定的触发类型对应价格，此处直接报错，统一规范后 删掉该代码

            console.error('请填入触发价格类型对应的最新类型价格：priceByTriggerType');
            return;
          }
          const tpSlTypeMap = {
            PLAN_LIMIT: {
              tp: 'TAKE_PROFIT_LIMIT',
              sl: 'STOP_LIMIT'
            },
            PLAN_MARKET: {
              tp: 'TAKE_PROFIT_MARKET',
              sl: 'STOP_MARKET'
            }
          };
          if (params.orderSide === 'BUY') {
            params.type =
              Number(data.triggerPrice) > Number(data.priceByTriggerType)
                ? tpSlTypeMap[orderType].sl
                : tpSlTypeMap[orderType].tp;
          } else {
            params.type =
              Number(data.priceByTriggerType) > Number(data.triggerPrice)
                ? tpSlTypeMap[orderType].sl
                : tpSlTypeMap[orderType].tp;
          }

          params.triggerPrice = data.triggerPrice;
          params.triggerPriceType = getTriggerPriceType(data.triggerType);
          params.orderSource = 'PLAN';
          break;
        case 'TAKE_PROFIT_MARKET': // 新系统止赢止损 使用市價
        case 'STOP_MARKET':
          params.type = orderType;
          params.positionTpsl = true;
          params.reduceOnly = true;
          params.triggerPrice = data.stopPrice;
          params.triggerPriceType = getTriggerPriceType(data.triggerType);
          break;
        case 'TAKE_PROFIT_LIMIT': // TODO 新系统止赢止损 没有【执行限价】（到达触发价后激活订单、使用执行限价 成交），只有触发类型、触发价
        case 'STOP_LIMIT':
          params.type = orderType;
          params.positionTpsl = true;
          params.reduceOnly = true;
          params.triggerPrice = data.stopPrice;
          params.triggerPriceType = getTriggerPriceType(data.triggerType);
          break;
        default:
          params.type = orderType; // LIMIT | MARKET
      }
      // 订单价格字段
      params.price =
        params.type.indexOf('MARKET') > -1
          ? 0
          : typeof data.tradePrice !== 'undefined'
          ? data.tradePrice
          : data.executePrice; // TODO 需要确实 是否兼容 上述 【执行限价】的情况

      // 确定timeInForce
      // type为 LIMIT/STOP_LIMIT/TAKE_PROFIT_LIMIT 时有意义。此字段必填，市价单必为IMMEDIATE_OR_CANCEL.
      // 0普通 gtc,1 postOnly,2 fok, 3 ioc 如果不传，代表普通单
      const timeInForceEnum = ['GOOD_TIL_CANCEL', 'POST_ONLY', 'FILL_OR_KILL', 'IMMEDIATE_OR_CANCEL'];
      params.timeInForce = ['MARKET', 'STOP_MARKET', 'TAKE_PROFIT_MARKET'].includes(params.type)
        ? 'IMMEDIATE_OR_CANCEL'
        : timeInForceEnum[Number(data.timeInForce)] || 'GOOD_TIL_CANCEL';
      paramsList.forEach(([key, value]) => {
        switch (true) {
          case key === 'presetTakeProfitPrice': // 预设止赢
            if (value) {
              params.setOpenTp = true;
              params.openTpParam = {
                side: params.orderSide === 'BUY' ? 'SELL' : 'BUY', // 止盈方向 与 下单方向相反
                size: params.size,
                price: 0,
                clientOrderId: generateUniqueString(), // 64位
                triggerPrice: value,
                triggerPriceType: 'LAST_PRICE'
              };
            }
            break;
          case key === 'presetStopLossPrice': // 预设止损
            if (value) {
              params.setOpenSl = true;
              params.openSlParam = {
                side: params.orderSide === 'BUY' ? 'SELL' : 'BUY', // 止盈方向 与 下单方向相反
                size: params.size,
                price: 0, // 下单止盈止损为市价单
                clientOrderId: generateUniqueString(), // 64位
                triggerPrice: value,
                triggerPriceType: 'LAST_PRICE'
              };
            }
            break;
          default:
            if (paramsKeyMap[key] !== ABANDONED) {
              params[paramsKeyMap[key] || key] = value;
            }
        }
      });
      params.orderSource = params.orderSource || 'WEB';
    }
    return params;
  },

  res: res => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }
};

export default createOrderAdapter;
