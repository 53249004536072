import { ABANDONED } from '../config';
import { isClient } from '~/utils/envHelper';
const newParams = {
  languageType: 0,
  sign: 'SIGN',
  timeZone: 'string',
  orderIdList: [
    // 委托单id
    'string'
  ]
};
// 1、contract/cancel     取消合约
const contractCancel = {
  orderNo: '1063717706886283213',
  productCode: 'cmt_ethusdt',
  languageType: 1
};

// 2、contract/cancelPlan 取消计划委托
const contractCancelPlan = {
  productCode: 'cmt_ethusdt',
  orderNo: '1063718340922441728',
  languageType: 1
};

const paramsKeyMap = {
  productCode: ABANDONED,
  orderNo: ABANDONED
};
const cancelOrderByIdAdapter = {
  req: data => {
    const params = {};
    if (isClient) {
      const paramsList = window.Object.entries(data);
      paramsList.forEach(([key, value]) => {
        switch (true) {
          case key === 'orderNo':
            params.orderIdList = Array.isArray(value) ? value : [value]; // 兼容传入 多个id 的数组情形
            break;
          default:
            if (paramsKeyMap[key] !== ABANDONED) {
              params[paramsKeyMap[key] || key] = value;
            }
        }
      });
    }
    return params;
  },

  res: res => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }
};

export default cancelOrderByIdAdapter;
