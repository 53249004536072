

import { getInstance } from '../../utils/doRequest'
const commonInstance = getInstance('');

// 币币账户财务列表
export const financeList = data => {
  return commonInstance.post('/v1/if/finance/financeList', data)
}


// 币币账户币种列表
export const conditionList = data => {
  return commonInstance.post('/v1/if/finance/conditionList', data)
}


// 通过站点id获取该站点下支持的交易对列表
export const leverExchangeLeft = data => {
  return commonInstance.post('/v1/if/lever/exchangeList', data)
}


export const transferAssets = data => {
  return commonInstance.post('/v1/assets/transfer', data)
}


// 站点账户列表
export const basicSite = data => {
  return commonInstance.post('/v1/if/lever/basicSite', data)
}



// 杠杆财务记录 详情
export const leverFinanceDetail = data => {
  return commonInstance.post('/v1/if/finance/leverFinanceDetail', data)
}


// 划转的站点 币种 账户列表
export const accountList = data => {
  return commonInstance.post('/v1/assets/accountList', data)
}

//币种 可划转数量
export const canTransfetAsset = data => {
  return commonInstance.post('/v1/assets/getAssets', data)
}
