
const klineHistoryAdapter = {
  req: data => {
    const params = {
      languageType: 1,
      sign: 'SIGN',
      timeZone: 'string'
    };
    return { ...params, ...data };
  },

  res: res => {
    return new Promise((resolve, reject) => {
      const {
        code,
        data: { dataList, nextFlag, nextKey },
        responseTime
      } = res;
      const tempKlineList = [];
      if (dataList === undefined) return resolve(res);
      dataList.forEach(item => {
        const newKline = {
          open: Number(item.open),
          close: Number(item.close),
          high: Number(item.high),
          low: Number(item.low),
          volume: Number(item.size),
          time: Number(item.klineTime),
          timestamp: Number(item.klineTime),
          contractId:item.contractId
        };
        tempKlineList.push(newKline);
      });

      const result = {
        code,
        data: { dataList: tempKlineList, nextFlag, nextKey },
        restime: responseTime
      };

      resolve(result);
    });
  }
};

export default klineHistoryAdapter;
