import axios from 'axios';
import { getNewAPIUrl } from '~/utils/urlHelper.js';
import HMM from "~/web-base/components/HandmadeMessage";
import sEvent from "../../../utils/sensorsEvent";
import { isClient, isProduction, isRC } from '~/utils/envHelper.js';
import TokenCookie, {TokenKey} from "~/utils/TokenCookie";

const axiosInstance = axios.create({
    timeout: 20000
});

axiosInstance.interceptors.request.use(
    async(config) => {
        let uToken = isClient && TokenCookie.get(TokenKey.CC_TOKEN);
        if (uToken) {
            config.headers['u-token'] = uToken;
        }
        // 如果生产和RC环境则获取域名，否则从环境变量中取
        const baseUrl =  (isProduction || isRC) ? await getNewAPIUrl() : process.env.API_BASE_URL_NEW;
        // console.log('0000 baseUrl', baseUrl)
        if(baseUrl && baseUrl.length > 0 && baseUrl !=='/'){
            config.baseURL = baseUrl;
        }
        // 记录请求开始时间
        config.startTime = Date.now();
        return config;
    },
    error => {
        // 超时取消请求时弹出提示
        let i18n = require('../../../config/request.i18n.conf')();
        HMM({ type: 'error', message: i18n.timeout });
        return Promise.resolve(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
        // 计算请求时延
        const duratime = Date.now() - response.config.startTime;

        // 神策埋点事件ajax_response_time
        sEvent('ajax_response_time', response.request.responseURL,{duration: duratime});
        const { status, data } = response;
        if (status === 200) {
            // 如果响应Code!=SUCCESS，神策上报错误信息
            if(data.code !== 'SUCCESS'){
                sEvent('ajax_error',  response.request.responseURL,{ext: data.msg});
            }
            // 返回data数据并处理code由页面处理
            // 页面调用已经处理code!==SUCCESS情况，所以拦截器不需要处理
            return {
                ...data,
                code: data.code === 'SUCCESS' ? '00000' : data.code
            };
        }
        return response;
    },
    err => {
        // 请求失败时获取异常请求地址
        const eventValue = err && err.response ? err.response.request?.responseURL : err.config?.url;
        // 请求失败时获取异常信息
        const errInfo = err && err.response ?  JSON.stringify(err.response?.data) : err.code
        // 神策埋点事件ajax_error
        sEvent('ajax_error', eventValue,{ext: errInfo});

        let i18n = require('../../../config/request.i18n.conf')();
        // 如果可以取到响应内容则根据响应判断
        if (err.response) {
            switch (err.response.status) {
                case 504:
                case 404:
                    HMM({ type: 'error', message: i18n.notfound });
                    break;
                case 403:
                    HMM({ type: 'error', message: i18n.forbidden });
                    break;
                // 未登录接口会返回401状态码，401状态码跳转登录
                case 401:
                    HMM({ type: 'error', message: i18n.expired });
                    window.$nuxt.$logout(window.$nuxt.context).then(() => {
                        window.location.href=`/${i18n.locale}login`;
                    });
                    break;
                case 500:
                    HMM({ type: 'error', message: i18n.unknow });
                    break;
                default:
                    // 如果不是预知状态码，则判断时候有返回提示信息
                    if(err.response?.data?.msg){
                        // 有提示信息则弹出提示信息
                        HMM({ type: 'error', message: err.response.data.msg });
                    }else {
                        // 没有提示信息则弹出未知
                        HMM({ type: 'error', message: i18n.unknow });
                    }
                    break;
            }
        } else {
            // 没有响应内容则判断如果是请求超时弹出提示
            if (err.code === 'ECONNABORTED' && err?.message?.includes('timeout')) {
                // 请求超时 处理
                HMM({ type: 'error', message: i18n.timeout });
            }
        }
        return Promise.reject(err);
    }
);

function request() {
    return {
        post: (url, data) => {
            // 历史遗留问题，修复languageType参数异常
            // 因时序问题导致i18获取语言异常，改从store中初始化
            let i18n = require('../../../config/request.i18n.conf')(isClient ? window?.$nuxt?.$store?.state?.locale || 'en' : 'en');
            data = Object.assign({}, data, { languageType: i18n.lang });
            return  axiosInstance.post(url, data || {})
        },
        get: (url, data) => {
            // 历史遗留问题，修复languageType参数异常
            // 因时序问题导致i18获取语言异常，改从store中初始化
            let i18n = require('../../../config/request.i18n.conf')(isClient ? window?.$nuxt?.$store?.state?.locale || 'en' : 'en');
            data = Object.assign({}, data, { languageType: i18n.lang });
            return  axiosInstance.get(url, data ? {params: data} : {})
        }
    };
}

const tradingRequest = request();
export default tradingRequest;
