
/**
 * 获取node 系统时间
 * @param {} data
 */
export const getCurrentTime = data => {
  return new Promise(resolve => {
    resolve(Date.now())
  })
}
