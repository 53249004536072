import { ABANDONED } from '../config';
import { isClient } from '~/utils/envHelper';

const paramsKeyMap = {
  languageType: 'languageType',
  orderId: 'orderId',
  clientOrderId: 'clientOrderId',
  updatePrice: 'updatePrice',
};
const updateOrderLimitPriceAdapter = {
  req: data => {
    const params = {};
    if (isClient) {
      const paramsList = window.Object.entries(data);
      paramsList.forEach(([key, value]) => {
        switch (true) {
          default:
            if (paramsKeyMap[key] !== ABANDONED) {
              params[paramsKeyMap[key] || key] = value;
            }
        }
      });
    }
    return params;
  },

  res: res => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }
};

export default updateOrderLimitPriceAdapter;
