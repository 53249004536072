import { ABANDONED } from '../config';
import { isClient } from '~/utils/envHelper';
const newParams = {
  languageType: 0,
  language: 'en_US',
  sign: 'SIGN',
  timeZone: 'string',
  type: "string"
};

const paramsKeyMap = {
  productCode: ABANDONED,
  orderNo: ABANDONED
};
const checkContractEnableDisplayAdapter = {
  // req: data => {
  //   const params = {};
  //   const paramsList = window.Object.entries(data);
  //   paramsList.forEach(([key, value]) => {
  //     switch (true) {
  //       case key === 'orderNo':
  //         params.orderIdList = Array.isArray(value) ? value : [value]; // 兼容传入 多个id 的数组情形
  //         break;
  //       default:
  //         if (paramsKeyMap[key] !== ABANDONED) {
  //           params[paramsKeyMap[key] || key] = value;
  //         }
  //     }
  //   });
  //   return params;
  // },

  req: data => {
    const params = {};
    const paramsList = isClient && window.Object.entries(data);
    paramsList.forEach(([key, value]) => {
      switch (true) {
        default:
          if (paramsKeyMap[key] !== ABANDONED) {
            params[paramsKeyMap[key] || key] = value;
          }
      }
    });
    return params;
  },

  res: res => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }
};

export default checkContractEnableDisplayAdapter;
