import contractListAdapter from './zone/contractList';
import klineHistoryAdapter from './zone/getKlineHistory';
import accountAssetAdapter from './zone/getAccountAsset';
import accountAdapter from './zone/getAccount';
import moveMarginAdapter from './zone/moveMargin';
import createOrderAdapter from './zone/createOrder';
import getHistoryOrderAdapter from './zone/getHistoryOrder';
import getCurrentOrderAdapter from './zone/getCurrentOrder';
import cancelOrderByIdAdapter from './zone/cancelOrderById';
import cancelAllOrderAdapter from './zone/cancelAllOrder';
import closeAllPositionAdapter from './zone/closeAllPosition';
import reversePositionAdapter from './zone/reversePosition';
import updateLeverageAdapter from './zone/updateLeverage';
import updateModeAdapter from './zone/updateMode';
import updateAutoAppendMarginAdapter from './zone/updateAutoAppendMargin';
import adjustIsolatedPositionMarginAdapter from './zone/adjustIsolatedPositionMargin';
import updateOrderTriggerPriceAdapter from './zone/updateOrderTriggerPrice';
import updateOpenTpSlTriggerPriceAdapter from './zone/updateOpenTpSlTriggerPrice';
import getHistoryOrderDetailByIdAdapter from './zone/getHistoryOrderDetailById';
import getFinancialConditionListAdapter from './zone/getFinancialConditionList';
import getMultiLanguageItemAdapter from './zone/getMultiLanguageItem';
import checkContractEnableDisplayAdapter from './zone/checkContractEnableDisplay';
import getFundingHistory from "@/server/newTradingApi/adapter/zone/getFundingHistory";
// 历史仓位
import getHistoryPositionListAdapter from './zone/getHistoryPositionList';
import updateOrderLimitPriceAdapter from "@/server/newTradingApi/adapter/zone/updateOrderLimitPrice";

const ApiAdapter = {
  contractList: contractListAdapter,
  klineHistoryList: klineHistoryAdapter,
  account: accountAdapter,
  accountAsset: accountAssetAdapter,
  createOrder: createOrderAdapter,
  moveMargin: moveMarginAdapter,
  getHistoryOrder: getHistoryOrderAdapter,
  getCurrentOrder: getCurrentOrderAdapter,
  cancelOrderById: cancelOrderByIdAdapter,
  cancelAllOrder: cancelAllOrderAdapter,
  closeAllPosition: closeAllPositionAdapter,
  reversePosition: reversePositionAdapter,
  updateLeverage: updateLeverageAdapter,
  updateMode: updateModeAdapter,
  updateAutoAppendMargin: updateAutoAppendMarginAdapter,
  adjustIsolatedPositionMargin: adjustIsolatedPositionMarginAdapter,
  updateOrderTriggerPrice: updateOrderTriggerPriceAdapter,
  updateOrderLimitPrice: updateOrderLimitPriceAdapter,
  updateOpenTpSlTriggerPrice: updateOpenTpSlTriggerPriceAdapter,
  getHistoryOrderDetailById: getHistoryOrderDetailByIdAdapter,
  getHistoryPositionList: getHistoryPositionListAdapter,
  getFinancialConditionList: getFinancialConditionListAdapter,
  getMultiLanguageItem: getMultiLanguageItemAdapter,
  checkContractEnableDisplay: checkContractEnableDisplayAdapter,
  getFundingHistory: getFundingHistory
};

export default ApiAdapter;
