import { ABANDONED } from '../config';
import { isClient } from '~/utils/envHelper';
const newParams = {
  languageType: 0,
  sign: 'SIGN',
  timeZone: 'string',
  contractId: 'string',
  marginMode: '', // SHARED, CROSS, ISOLATED
  separatedMode: '', // COMBINED, SEPARATED
  positionMode: 'UNKNOWN_POSITION_MODE' // ONE_WAY, HEDGE, UNRECOGNIZED
};

// contract/switchHoldMode 修改仓位模式，全仓——逐仓
const contractSwitchHoldMode = {
  productCode: '',
  holdMode: '', // 持仓模式 1逐仓 -> ISOLATED 2全仓 -> CROSS
  subHoldMode: '', // 分合仓模式 1-合仓 2-分仓，默认值：1
  positionMode: 'UNKNOWN_POSITION_MODE' // TODO 需要新增仓位模式 ONE_WAY - 单向, HEDGE - 双向
};

const paramsKeyMap = {
  productCode: 'contractId',
  positionMode: 'positionMode', // 新增 增加仓位模式 ONE_WAY - 单向, HEDGE - 双向
  holdMode: ABANDONED,
  subHoldMode: ABANDONED
};
const updateModeAdapter = {
  req: data => {
    const params = {};
    if (isClient) {
      const paramsList = window.Object.entries(data);
      paramsList.forEach(([key, value]) => {
        switch (true) {
          case key === 'holdMode':
            const moduleMap = {
              1: 'ISOLATED',
              2: 'SHARED'
            };
            params.marginMode = moduleMap[Number(value)] || 'SHARED'; // 不是 1 或 2 时为 SHARED
            break;
          case key === 'subHoldMode':
            const separatedModeMap = {
              1: 'COMBINED',
              2: 'SEPARATED'
            };
            params.separatedMode = separatedModeMap[Number(value)] || 'COMBINED'; // 1 2 之外 其他值也为 COMBINED
            break;
          default:
            if (paramsKeyMap[key] !== ABANDONED) {
              params[paramsKeyMap[key] || key] = value;
            }
        }
      });
    }
    return params;
  },

  res: res => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }
};

export default updateModeAdapter;
