
import { getInstance } from '~/utils/doRequest'

const mcpInstance = getInstance('/v1/mcp');


/**
 * 查询财务记录列表
 * @param {*} data
 */
export const getFinancialList = (data) => {
  return mcpInstance.post('/financial/getFinancialList', data);
}

/**
 * 财务记录查询条件列表
 */
export const getFinancialQueryConditions = (data) => {
  return mcpInstance.post('/financial/getFinancialQueryConditions', data);
}


/**
 * 混合合约币对列表
 */
export const getMcpContractList = (data) => {
  return mcpInstance.post('/contract/list', data);
}

/**
 * 混合合约订单查询条件
 */
export const getOrderQueryConditions = (data) => {
  return mcpInstance.post('/order/getOrderQueryConditions', data);
}

/**
 * 混合合约历史订单
 */
export const getHistoryOrderList = (data) => {
  return mcpInstance.post('/order/getHistoryOrderList', data);
}

/**
 * 混合合约成交记录
 */
export const getDealRecordList = (data) => {
  return mcpInstance.post('/order/getDealRecordList', data);
}

/**
 * 混合合约当前委托
 */
export const getCurrentOrderList = (data) => {
  return mcpInstance.post('/order/getCurrentOrderList', data);
}

/**
 * 混合合约某笔订单下的成交明细
 */
export const getOrderDealDetailList = (data) => {
  return mcpInstance.post('/order/getOrderDealDetailList', data);
}

/**
 * 混合合约撤单
 */
export const cancelContract = (data) => {
  return mcpInstance.post('/order/cancelContract', data);
}

/**
 * 混合合约资产及保证金模式
 */
export const mcpAsset = (data) => {
  return mcpInstance.post('/account/token', data);
}

/**
 * 混合合约账户资产
 */
export const mcpAccountList = (data) => {
  return mcpInstance.post('/account/list', data);
}

/**
 * 混合合约账户资产-汇总 用于 总资产展示
 */
export const mcpAssetSum = (data)=>{
    return  mcpInstance.post('/account/sum/list',data);
}

/**
 * 当前委托 普通委托 批量撤单
 */
export const batchCancelContract = (data)=>{
    return  mcpInstance.post('/order/batchCancelContract',data);
}


/**
 * 平仓
 * @param {*} data
 *
 */
export const closeContract = data => {
  return mcpInstance.post('/order/trader/placeContract', data);
}

/**
 * 全部平仓
 * @param {*} data
 *
 */
export const batchCloseContract = data => {
  return mcpInstance.post('/order/batchCloseContract', data);
}
