// 前端 统一 server 端服务封装

import { formatUserInfo } from '~/utils/help';

import { getInstance } from '~/utils/doRequest';

let userInstance = getInstance('/v1/user');

// 手机注册
export const registerForMobile = data => {
  return userInstance
    .post('/register/mobile', data)
    .then(res => {
      let result = res;
      if (result.data) {
        result.data.userInfo = formatUserInfo(result.data);
      }
      return result;
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

// 邮箱注册
export const registerForEmail = data => {
  return userInstance.post('/register/email', data).then((res)=>{
    let result = res;
    if(result.data){
       result.data.userInfo = formatUserInfo(result.data);
    }
    return result;
  }).catch((err)=>{
     return Promise.reject(err);
  })
}
// 登录第一步
export const loginCheck = data => {
  return userInstance
    .post('/login/new', data)
    .then(res => {
      if (res.code === '00000') {
        if (res.data.userInfo) {
          res.data.userInfo = formatUserInfo(res.data);
        }
      }
      return res;
    })
    .catch(err => {
      return Promise.reject(err);
    });
};
// 登录第二步
export const login = data => {
  return userInstance.post('/login/verify-login',data).then((res)=>{
    if(res.data){
       res.data.userInfo = formatUserInfo(res.data);
    }
    return res;
  }).catch((err)=>{
     return Promise.reject(err);
  })
}
/**
 * 清除网站cookie
 * @param {*} data
 */
export const invokeClearCookie = data => {
  return userInstance.post('/public/clearCookie');
}

/***
 * 系统账号 登录第一步
 */
export const systemLogin = data => {
  return userInstance.post('/login/systemLogin', data);
};

/***
 * 系统登录 第二步
 *  */
export const verifySystemLogin = data => {
  return userInstance
    .post('/login/verifySystemLogin', data)
    .then(res => {
      let result = res;
      if (result.data) {
        result.data.userInfo = formatUserInfo(result.data);
      }
      return result;
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

export const getUserInfo = data => {
  return userInstance
    .post('/overview/userinfo', data)
    .then(res => {
      let result = res;
      if (result.data) {
        result.data.userInfo = formatUserInfo(result.data);
      }
      return result;
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

// 扫码登录 - key
export const getQrKey = data => {
  return userInstance.post('/public/getQrKey', data);
};

// 扫码登录 - 状态
// export const getQrKeyStatus = data => {
//   return userInstance
//     .post('/public/getQrKeyStatus', data)
//     .then(res => {
//       let result = res;
//       if (result.data && result.data.userInfo) {
//         result.data.userInfo = formatUserInfo(result.data);
//       }
//       return result;
//     })
//     .catch(err => {
//       return Promise.reject(err);
//     });
// };

// 忘记密码-确认
export const forgetPwdConfirm = data => {
  return userInstance.post('/forget-pwd/confirm', data);
};

// 忘记密码-重置
export const forgetPwdReset = data => {
  return userInstance.post('/forget-pwd/reset', data);
};

// 忘记密码-重置-new
export const forgetPwdResetNew = data => {
  return userInstance.post('/forget-pwd/reset-new', data);
};

export const feedbackSave = data => {
  return userInstance.post('/feedback/save', data);
};

export const feedbackHistory = data => {
  return userInstance.post('/feedback/page', data);
};

// 获取用户vipCode，如果没有则获取用户上级的vipCode，如果上级也没有，则返回空
export const userVipCode = data => {
  return userInstance.post('/overview/userVipCode',data);
};

/**
 * 获取qr登录信息
 */
export const getQrcodeKey = _ => {
  return userInstance.post('/public/getQrcodeKey');
};

/**
 * 获取qr登录状态
 */
export const getQrKeyStatus = data => {
  return userInstance.post('/public/getQrKeyStatus', data);
};
