import { ABANDONED } from '../config';
import { isClient } from '~/utils/envHelper';
const newParams = {
  languageType: 0,
  sign: 'SIGN',
  timeZone: 'string',
  contractIdList: ['string'], // 过滤获取对应合约的活动委托单，如果为空则获取所有合约的活动委托单
  pageNo: 0,
  pageSize: 0
};

const newRes = {
  code: 'string',
  data: {
    dataList: [
      {
        id: 'string',
        accountId: 'string',
        contractId: 'string',
        marginMode: 'UNKNOWN_MARGIN_MODE',
        separatedMode: 'UNKNOWN_SEPARATED_MODE',
        separatedOpenOrderId: 'string',
        positionSide: 'UNKNOWN_POSITION_SIDE',
        orderSide: 'UNKNOWN_ORDER_SIDE',
        price: 'string',
        size: 'string',
        leverage: 'string',
        clientOrderId: 'string',
        type: 'UNKNOWN_ORDER_TYPE',
        timeInForce: 'UNKNOWN_TIME_IN_FORCE',
        reduceOnly: true,
        triggerPrice: 'string',
        triggerPriceType: 'UNKNOWN_PRICE_TYPE',
        extraType: 'string',
        extraDataJson: 'string',
        openTpslParentOrderId: 'string',
        openTpParam: {
          side: 'UNKNOWN_ORDER_SIDE',
          price: 'string',
          size: 'string',
          clientOrderId: 'string',
          triggerPrice: 'string',
          triggerPriceType: 'UNKNOWN_PRICE_TYPE'
        },
        openSlParam: {
          side: 'UNKNOWN_ORDER_SIDE',
          price: 'string',
          size: 'string',
          clientOrderId: 'string',
          triggerPrice: 'string',
          triggerPriceType: 'UNKNOWN_PRICE_TYPE'
        },
        status: 'UNKNOWN_ORDER_STATUS',
        matchSequenceId: 'string',
        triggerTime: 'string',
        triggerPriceTime: 'string',
        triggerPriceValue: 'string',
        cancelReason: 'UNKNOWN_ORDER_CANCEL_REASON',
        latestFillPrice: 'string',
        maxFillPrice: 'string',
        minFillPrice: 'string',
        cumFillSize: 'string',
        cumFillValue: 'string',
        cumFillFee: 'string',
        cumLiquidateFee: 'string',
        createdTime: 'string',
        updatedTime: 'string',
        fallback: true,
        liquidate: true,
        deleverage: true,
        positionTpsl: true,
        setOpenTp: true,
        setOpenSl: true,
        withoutMatch: true
      }
    ],
    nextPageOffsetData: 'string'
  },
  requestTime: 'string',
  responseTime: 'string'
};

// 1、contract/currentPlan  //当前计划查询
const contractCurrentPlan = {
  pageSize: 10,
  pageNo: 1,
  productCode: 'cmt_btcusdt',
  dateType: 0, // 时间段类型(0:当前 1:最近7天 2:7天到1个月 3:7天到3个月)
  direction: 1, // 方向 1开多 2开空 3平多 4平空
  languageType: 1
};

// 2、contract/currentOrder //当前委托(合约交易记录)
const contractCurrentOrder = {
  pageSize: 10,
  pageNo: 1,
  productCode: 'cmt_btcusdt',
  dateType: 0, // 时间段类型(0:当前 1:最近7天 2:7天到1个月 3:7天到3个月)
  direction: 1, // 方向 1开多 2开空 3平多 4平空
  languageType: 1
};

const paramsKeyMap = {
  productCode: 'coinId',
  amount: 'collateralAmount'
};
const getCurrentOrderAdapter = {
  req: data => {
    const params = {};
    if (isClient) {
      const paramsList = window.Object.entries(data);
      paramsList.forEach(([key, value]) => {
        switch (true) {
          default:
            if (paramsKeyMap[key] !== ABANDONED) {
              params[paramsKeyMap[key] || key] = value;
            }
        }
      });
    }
    return params;
  },

  res: res => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }
};

export default getCurrentOrderAdapter;
