import { ABANDONED } from '../config';
import { isClient } from '~/utils/envHelper';
const newParams = {
  languageType: 0,
  sign: 'SIGN',
  timeZone: 'string',
  orderId: 'string',
  triggerPrice: 'string'
};

// contract/updatePlanOrTPSL 修改计划委托或止盈止损
const contractUpdatePlanOrTPSL = {
  productCode: 'cmt_ethusdt',
  orderNo: '1063698059906515228',
  planType: 0,
  executePrice: '',
  triggerPrice: '2200.00',
  triggerType: 1,
  orderType: 1,
  languageType: 1
};

const paramsKeyMap = {
  productCode: 'coinId',
  amount: 'collateralAmount',
  orderNo: 'orderId',
  executePrice: 'updatePrice',
  triggerPrice: 'updateTriggerPrice',
  triggerType: 'updateTriggerPriceType'
};
const updateOrderTriggerPriceAdapter = {
  req: data => {
    const params = {};
    if (isClient) {
      const paramsList = window.Object.entries(data);
      paramsList.forEach(([key, value]) => {
        switch (true) {
          default:
            if (paramsKeyMap[key] !== ABANDONED) {
              params[paramsKeyMap[key] || key] = value;
            }
        }
      });
    }
    return params;
  },

  res: res => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }
};

export default updateOrderTriggerPriceAdapter;
