import { ABANDONED } from "../config";
import { isClient } from '~/utils/envHelper';
const newParams = {
  "languageType": 0,
  "sign": "SIGN",
  "timeZone": "string",
  "positionId": "string",
  "coinId": "string",
  "isolatedPositionId": "string",
  "collateralAmount": "string"
}

const paramsKeyMap = {
  positionId: 'positionId', // 新增 逐仓仓位id
  productCode: ABANDONED,
  side: ABANDONED,
  openOrderNo: ABANDONED
};
// 只有逐仓模式才能使用
const adjustIsolatedPositionMarginAdapter = {
  req: data => {
    const params = {};
    if(isClient){
      const paramsList =  window.Object.entries(data);
      paramsList.forEach(([key, value]) => {
        switch (true) {
          case key === 'afterAppendType':
            params.autoAppendMargin = Boolean(value); // 0 - false 1 -true
            break;
          default:
            if (paramsKeyMap[key] !== ABANDONED) {
              params[paramsKeyMap[key] || key] = value;
            }
        }
      });
    }
    return params;
  },

  res: res => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }
};

export default adjustIsolatedPositionMarginAdapter;
