import { ABANDONED } from '../config';
import { isClient } from '~/utils/envHelper';

const paramsKeyMap = {
  languageType: 'languageType',
  language: 'language',
  sign: ABANDONED,
  timeZone: ABANDONED,
  contractId: 'contractId',
  pageNo: 'pageNo',
  pageSize: 'pageSize',
  filterBeginTimeInclusive: "filterBeginTimeInclusive",
  filterEndTimeExclusive: "filterEndTimeExclusive"
};

const getFundingHistory = {
  req: data => {
    const params = {};
    const paramsList = isClient && window.Object.entries(data);
    paramsList.forEach(([key, value]) => {
      if (paramsKeyMap[key] !== ABANDONED) {
        params[paramsKeyMap[key] || key] = value;
      }
    });
    return params;
  },

  res: res => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }
};

export default getFundingHistory;
