import { Decimal } from 'decimal.js';

/**
 * 将后端未正常返回数值 或 布尔型的 数据 转换成 对应的 数值 或 布尔型
 * @param obj
 * @returns {*}
 */
export function toNumberOrBoolean(obj) {
  // 判断是否为对象
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      // 如果是数组，递归遍历每个元素
      for (let i = 0; i < obj.length; i++) {
        obj[i] = toNumberOrBoolean(obj[i]);
      }
    } else {
      // 如果是对象，递归遍历每个属性
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          obj[key] = toNumberOrBoolean(obj[key]);
        }
      }
    }
  } else if (typeof obj === 'string') {
    switch (true) {
      case obj === 'true':
        obj = true;
        break;
      case obj === 'false':
        obj = false;
        break;
      default:
        const num = Number(obj);
        if (!window.isNaN(num)) {
          obj = num;
        }
    }
  }
  return obj;
}

export function numAdd(...args) {
  return args.reduce((totalValue, currentValue) => {
    return Decimal.add(totalValue, Number(currentValue)).toNumber();
  }, 0);
}

export function numMultiply(...args: number[]) {
  return args.reduce((totalValue, currentValue) => {
    const currentNum = Number(currentValue);
    return Decimal.mul(totalValue, isNaN(currentNum) ? 1 : currentNum).toNumber();
  }, 1);
}

export function generateUniqueString(length = 64) {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let tempStr = String(Date.now());
  const reserveLen = tempStr.length;
  let randomStrLength = Math.floor(length - reserveLen);

  if (length < reserveLen) {
    tempStr = '';
    randomStrLength = length;
  }
  for (let i = 0; i < randomStrLength + 2; ++i) {
    const idx = Math.floor(Math.random() * characters.length);
    tempStr += characters[idx];
  }

  return tempStr.slice(0, length);
}
