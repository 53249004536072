import { cacheApiFunc } from '~/utils/cacheApi/index';
import { getInstance } from '../../utils/doRequest'
import {config} from '~/config/enum.conf.js';
const commonInstance = getInstance('');

/****
 * 历史委托
 */
export const contractHistoryOrder = data => {
  return commonInstance.post("/v1/if/contract/historyOrder", data);
};
/**
 * 历史委托详情
 * @param {*} data
 */
export const contractOrderDetailList = data => {
  return commonInstance.post("/v1/if/contract/orderDetailList", data);
};

/****
 * 当前委托
 */
export const contractCurrentOrder = data => {
  return commonInstance.post("/v1/if/contract/currentOrder", data);
};

/****
 * 成交明细
 */
export const contractOrderDetail = data => {
  return commonInstance.post("/v1/if/contract/orderDetail", data);
};

/****
 * 撤销下单
 */
export const contractCancel = data => {
  return commonInstance.post("/v1/if/contract/cancel", data);
};

export const contractPlace = (data, delegateType = 1) => {
  if (delegateType !== 3) {
    // 普通委托
    // return commonInstance.post("/v1/if/contract/place", data);
    return tlAction(data, '/v1/if/contract/v2/place');
  } else {
    // 计划委托
    return commonInstance.post("/v1/if/contract/placePlan", data);
  }
};

/**
 * 套利用户延迟处理业务
 * @param data
 * @param url
 * @param type 1:開倉;2:平倉
 * @returns {Promise<unknown>}
 */
function tlAction(data, url, type) {
  let opt_fail_tips = data.err_tips;
  return new Promise(function (resolve, reject) {
    commonInstance.post(url, data).then(res => {
      if (res.code === config.error.SUCCESS && res.data && res.data.requestNos && res.data.requestNos.length > 0) { // 套利用户
        let ckInterval = setInterval(_ => {
          // 查结果
          commonInstance.post("/v1/if/contract/v2/queryByRequestNo", {
            //languageType: data.languageType || 1,
            type: type || data.type,
            contractTradeQueries: [{productCode: data.productCode || data.contractCloseAllPositionDtos[0].productCode, requestNos: res.data.requestNos}]
          }).then(res2 => {
            if (res2.code === config.error.SUCCESS && res2.data && res2.data.queryContracts && res2.data.queryContracts.length > 0 && res2.data.queryContracts[0].extraCode != 9) {
              // 获取到了处理结果
              clearInterval(ckInterval);
              let resData = res2.data.queryContracts[0];
              let extraCode = 0;
              res2.data.queryContracts.forEach(data => {
                extraCode += Number(data.extraCode);
              })
              // if (extraCode == 0) resData.code = config.error.SUCCESS;
              // resolve(resData);
              if (extraCode == 0) {
                if(resData.code){
                  if(resData.code === "009999"){
                    resData.msg = this.$t('trade.operation.systemBusy');
                    resolve(resData);
                  }
                  else {
                    resData.msg += ", " + opt_fail_tips;
                    //resolve({code: config.error.ERROR,msg:""});
                    resolve(resData); //套利用户查询失败返回后端错误msg
                  }
                }

                else{
                  resData.code = config.error.SUCCESS;
                  resolve(resData);
                }
              }
            }
            else {
              console.log(res2)
            }
          })
        }, 1500)
      } else { // 非套利用户或查询失败
        resolve(res);
      }
    }).catch(err => {
      reject(err);
    })
  });
}

/****
 * 获取爆仓信息
 */
export const contractBurstOrderDetail = data => {
  return commonInstance.post("/v1/if/contract/burstOrderDetail", data);
};

/****
 * 风险准备金
 */
export const contractRiskPreparation = data => {
  return commonInstance.post("/v1/if/contract/riskPreparation", data);
};

/****
 * 用户仓位档位
 */
export const contractRiskLevel = data => {
  return commonInstance.post("/v1/if/contract/riskLevel", data);
};

/****
 * 仓位档位
 */
export const contractRiskLeverage = cacheApiFunc(data => {
  return commonInstance.post("/v1/if/contract/leverage", data);
});


/****
 * 仓位档位 - 集合
 */
export const contractLeverageList = data => {
  return commonInstance.post("/v1/if/contract/leverageAll", data);
};

/****
 * 资金费率列表
 */
export const contractFundingRate = data => {
  //return commonInstance.post("/v1/if/contract/fundingRate", data);
  return commonInstance.get("/v1/if/contract/v2/fundingRate", data);
};

/****
 * 合约财务记录列表
 */
export const contractFinanceList = data => {
  return commonInstance.post("/v1/if/contractFinance/financeList", data);
};

/****
 * 获取用户合约资产
 */
export const contractFinanceCapital = data => {
  return commonInstance.post("/v1/if/contractFinance/contractCapital", data);
};

/****
 * 查询合约财务记录条件列表
 */
export const contractFinanceConditionList = data => {
  return commonInstance.post("/v1/if/contractFinance/conditionList", data);
};

/****
 * 查询当前保证金信息
 */
export const contractMarginInfo = data => {
  return commonInstance.post("/v1/if/contract/marginInfo", data);
};

/**
 * 调整保证金
 * @param ctx amount=调整的数量 type=1|2 增加|减少 side=0|1 多仓|空仓 contractId=合约ID
 * @return {Promise<void>}
 */
export const contractAdjustMargin = data => {
  return commonInstance.post("/v1/if/contract/adjustMargin", data);
};

/**
 * 变更杠杆倍率
 * @param ctx levelRate=新杠杆倍率 side=0|1 多仓|空仓 contractId=合约id
 * @return {Promise<void>}
 */
export const contractLevelRate = data => {
  return commonInstance.post("/v1/if/contract/levelRate", data);
};

/****
 * 交易记录-查询条件列表
 */
export const contractConditionList = data => {
  return commonInstance.post("/v1/if/contract/conditionList", data);
};


/**
 * 历史仓位查询条件
 * @param {*} data
 */
export const contractPositionConditionList = data => {
  return commonInstance.post("/v1/if/contract/positionConditionList", data);
};

/**
 * 历史仓位
 * @param {*} data
 */
export const contractPositionHistoryFlow = data => {
  return commonInstance.post("/v1/if/contract/positionHistoryFlow", data);
};

export const queryAllCurrentOrders = data => {
  return commonInstance.post("/v1/if/contract/queryAllCurrentOrders", data);
};

/**
 * 获取体验金是否已经发放
 * @param ctx
 * @return {Promise<void>}
 */
export const grantFundStatus = data => {
  return commonInstance.post("/v1/if/contract/grantFundStatus", data);
};

/**
 * 请求发放体验金
 * @param ctx
 * @return {Promise<void>}
 */
export const grantFund = data => {
  return commonInstance.post("/v1/if/contract/grantFund", data);
};

/**
 * 计划委托
 * @param {*} data
 */
export const currentPlan = data => {
  return commonInstance.post("/v1/if/contract/currentPlan", data);
};

/**
 * 历史计划
 * @param {*} data
 */
export const historyPlan = data => {
  return commonInstance.post("/v1/if/contract/historyPlan", data);
};

/**
 * 取消计划委托
 * @param {*} data
 */
export const cancelPlan = data => {
  return commonInstance.post("/v1/if/contract/cancelPlan", data);
};

export const stopPlan = data => {
  return commonInstance.post("/v1/if/contract/stopPlan", data);
};

/**
 * 新的止盈止损
 * 参数：caitalPwd， delegateCount， planType（1止盈，2止损）， productCode， side（1多仓 2空仓）， stopPrice
 * @param data
 * @return {undefined|Promise|Promise<unknown>}
 */
export const newStopPlan = data => {
  return commonInstance.post("/v1/if/contract/newStopPlan", data);
};


/**
 * 自动追加保证金
 * @param ctx
 * @return {Promise<void>}
 */
export const modifyAutoAppendMargin = data => {
  return commonInstance.post("/v1/if/contract/modifyAutoAppendMargin", data);
};

/**
 * 设置跟单止盈止损
 * @param data
 * @return {undefined|Promise|Promise<unknown>}
 */
export const updateTrackingOrder = data => {
  return commonInstance.post("/v1/if/trace/updateTrackingOrder", data);
};

/**
 * 平带单仓位
 * @param data
 * @return {undefined|Promise|Promise<unknown>}
 */
export const closeTrackingOrder = data => {
  return commonInstance.post("/v1/if/trace/closeTrackingOrder", data);
  // return tlAction(data, '/v1/if/contract/v2/closeTrackingOrder', 2);
};

/**
 * 全平带单仓位
 * @param data
 * @return {undefined|Promise|Promise<unknown>}
 */
export const closeAllTrackingOrder = data => {
  // return commonInstance.post("/v1/if/trace/closeAllTrackingOrder", data);
  return tlAction(data, '/v1/if/trace/v2/closeAllTrackingOrder', 2);
};


/**
 * 新合约条件列表
 * @param {*} data
 */
export const financialConditionList = data => {
  return commonInstance.post("/v1/if/contractFinance/financialConditionList", data);
};

/**
 * 新合约列表
 * @param {*} data
 */
export const financialContractList = data => {
  return commonInstance.post("/v1/if/contractFinance/financialList", data);
};

/**
 * 更改持仓模式
 * @param data
 * @return {undefined|Promise|Promise<unknown>}
 */
export const switchHoldMode = data => {
  return commonInstance.post("/v1/if/contract/switchHoldMode", data);
};

/**
 * 全部撤单
 * @param data
 * @returns {undefined|Promise|Promise<unknown>}
 */
export const cancelAll = data => {
  return commonInstance.post("/v1/if/contract/cancelAll", data);
};

/**
 * 修改预设止盈止损
 * productCode，bizNo，type(1委托2计划)，presetTakeProfitPrice，presetStopLossPrice
 * @param data
 * @returns {undefined|Promise|Promise<unknown>}
 */
export const updatePresetTPSL = data => {
  return commonInstance.post('/v1/if/contract/updatePresetTPSL', data)
}

/**
 * 修改计划委托 或 止盈止损
 * productCode,orderNo,planType(0计划委托1止盈2止损),
 * executePrice,triggerPrice,triggerType(1市场价2合理标记价格)
 * @param data
 * @returns {undefined|Promise|Promise<unknown>}
 */
export const updatePlanOrTPSL = data => {
  return commonInstance.post('/v1/if/contract/updatePlanOrTPSL', data)
}

/**
 * 获取用户某交易对是否有跟随
 * 参数 productCode
 * 返回 data: boolean
 * @param data
 * @return {undefined|Promise|Promise<unknown>}
 */
export const getFollowByProductCode = data => {
  return commonInstance.post('/v1/trace/tracer/getFollowByProductCode', data)
}

/**
 * 一键平仓
 * @param data
 * @return {undefined|Promise|Promise<unknown>}
 */
export const closeAllPosition = data => {
  // return commonInstance.post('/v1/if/contract/closeAllPosition', data)
  return tlAction(data, '/v1/if/contract/v3/closeAllPosition', 2);
}

/**
 * 查询止盈止损委托价格限制
 * @param data
 * @return {undefined|Promise|Promise<unknown>}
 */
export const queryStopPlanPriceLimit = data => {
  return commonInstance.post('/v1/if/contract/queryStopPlanPriceLimit', data)
}

/**
 * 套利用户检查
 * @param data
 * @return {undefined|Promise|Promise<unknown>}
 */
export const arbitrageUserCheck = data => {
  return commonInstance.post('/v1/if/contract/arbitrageUserCheck', data)
}

/**
 * 一键平仓 - 多持仓
 * @param data
 * @return {undefined|Promise|Promise<unknown>}
 */
export const closeAllPositionBatch = data => {
  // return commonInstance.post('/v1/if/contract/closeAllPositionBatch', data)
  return tlAction(data, '/v1/if/contract/v2/closeAllPositionBatch', 2);
}

/**
 * 检测是否能一键平仓 - 多持仓
 * @param data
 * @return {undefined|Promise|Promise<unknown>}
 */
export const ckCanOnekeyClose = data => {
  return commonInstance.post('/v1/if/trace/checkTradeUser', data)
}

/**
 * 取得海報分享設定
 * @param ctx
 * @return {Promise<void>}
 */
 export const getPosterSharingConfig = data => {
  return commonInstance.post("/v1/user/public/getPosterSharingConfig", data);
};

/**
 * 取得海報分享設定(新接口)
 * @param posterType 海报类型(1:邀请海报/2:晒单海报)
 * @return {Promise<void>}
 */
export const getPosterList = data => {
  return commonInstance.get("/v1/user/public/listPosterSharingConfig", data);
};
/**
 * 取得海報分享者鏈接
 * @param ctx
 * @return {Promise<void>}
 */
 export const getUserShareContent = data => {
  return commonInstance.post("/v1/user/public/getUserShareContent", data);
};
