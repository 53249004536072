
import { getInstance } from '../../utils/doRequest'
const commonInstance = getInstance('');

/****
 * 活动信息
 */
export const actInfo = data => {
  return commonInstance.post('/v1/act/info', data);
}

/****
 * 队长报名
 */
export const personalSignUp = data => {
  return commonInstance.post('/v1/act/personal/signUp', data);
}

/****
 * 战队报名
 */
export const teamSignUp = data => {
  return commonInstance.post('/v1/act/team/signUp', data);
}

/****
 * 加入战队
 */
export const teamJoin = data => {
  return commonInstance.post('/v1/act/team/join', data);
}

/****
 * 战队列表
 */
export const teamList = data => {
  return commonInstance.post('/v1/act/team/list', data);
}

/****
 * 战队成员列表
 */
export const teamMembers = data => {
  return commonInstance.post('/v1/act/team/members', data);
}

/****
 * 队长详情
 */
export const teamInfo = data => {
  return commonInstance.post('/v1/act/team/info', data);
}

/****
 * 个人排行榜
 */
export const personalRank = data => {
  return commonInstance.post('/v1/act/personal/rank', data);
}

/****
 * 个人信息
 */
export const personalInfo = data => {
  return commonInstance.post('/v1/act/personal/info', data);
}

/****
 * 系统徽章列表
 */
export const logoList = data => {
  return commonInstance.post('/v1/act/logoList', data);
}

/****
 * 战队报名-普通
 */
export const personalSignUpV2 = data => {
  return commonInstance.post('/v1/act/team/personalSignUp', data);
}

/****
 * 加入战队-队长
 */
export const captainJoinV2 = data => {
  return commonInstance.post('/v1/act/team/captainJoin', data);
}
