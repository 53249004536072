import { ABANDONED } from '../config';
import { isClient } from '~/utils/envHelper';
const newParams = {
  languageType: 0,
  sign: 'SIGN',
  timeZone: 'string',
  contractId: 'string',
  marginMode: 'UNKNOWN_MARGIN_MODE', // 保证金模式 SHARED, CROSS, ISOLATED
  longLeverage: 'string',
  shortLeverage: 'string'
};

// contract/levelRate 修改合约杠杆倍数
const contractLevelRate = {
  productCode: '',
  levelRate: '',
  positionType: '', // 持仓类型 0多仓 1空仓
  marginMode: '' // TODO 需要新增 保证金模式 SHARED, CROSS
};

// const paramsKeyMap = {
//   productCode: 'contractId',
//   marginMode: 'marginMode', // 新增 保证金模式 SHARED, CROSS, ISOLATED
//   levelRate: ABANDONED,
//   positionType: ABANDONED
// };
const paramsKeyMap = {
  productCode: 'contractId',
  marginMode: 'marginMode', // 新增 保证金模式 SHARED, CROSS, ISOLATED
  longLeverage: 'longLeverage',
  shortLeverage: 'shortLeverage',
  levelRate: ABANDONED,
  positionType: ABANDONED
};
const updateLeverageAdapter = {
  req: data => {
    const params = {};
    if (isClient) {
      const paramsList = window.Object.entries(data);
      paramsList.forEach(([key, value]) => {
        switch (true) {
          case key === 'levelRate':
            break;
          // case key === 'positionType':
          //   params[Number(value) === 1 ? 'shortLeverage' : 'longLeverage'] = data.levelRate;
          //   break;
          default:
            if (paramsKeyMap[key] !== ABANDONED) {
              params[paramsKeyMap[key] || key] = value;
            }
        }
      });
    }
    console.log('00 params: ', params);
    return params;
  },

  res: res => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }
};

export default updateLeverageAdapter;
