import { ABANDONED } from '../config';
import { isClient } from '~/utils/envHelper';
const newParams = {
  languageType: 0,
  sign: 'SIGN',
  timeZone: 'string',
  coinId: 'string',
  fromMarginMode: 'UNKNOWN_MARGIN_MODE', // from 保证金模式 SHARED, CROSS, ISOLATED
  fromCrossContractId: 'string', // 当 from_margin_mode=CROSS 时，表示全仓保证金模式关联的合约id。其他情况必为0
  fromIsolatedPositionId: 'string', // 当 from_margin_mode=ISOLATED 时，表示逐仓保证金关联的合约id。其他情况必为0
  toMarginMode: 'UNKNOWN_MARGIN_MODE', // to 保证金模式 SHARED, CROSS, ISOLATED
  toCrossContractId: 'string', // 当 to_margin_mode=CROSS 时，表示全仓保证金模式关联的合约id。其他情况必为0
  toIsolatedPositionId: 'string', // 当 to_margin_mode=ISOLATED 时，表示逐仓保证金关联的合约id。其他情况必为0
  collateralAmount: 'string' // 抵押品数量 (正数为从shared模式追加保证金到目标模式，负数为从目标模式扣减保证金到shared模式)
};

// contract/adjustMargin //合约修改保证金
const contractAdjustMargin = {
  productCode: '',
  type: '', // 类型 1增加 2减少
  amount: '',
  positionType: '', // 持仓类型 0多仓 1空仓
  openOrderNo: '' // 分仓仓位的开仓订单编号 TODO
};

const paramsKeyMap = {
  productCode: 'coinId',
  amount: 'collateralAmount'
};
const moveMarginAdapter = {
  req: data => {
    const params = {};
    if (isClient) {
      const paramsList = window.Object.entries(data);
      paramsList.forEach(([key, value]) => {
        switch (true) {
          default:
            if (paramsKeyMap[key] !== ABANDONED) {
              params[paramsKeyMap[key] || key] = value;
            }
        }
      });
    }
    return params;
  },

  res: res => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }
};

export default moveMarginAdapter;
