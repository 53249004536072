import { cacheApiFunc } from '~/utils/cacheApi/index';
import {getInstance} from '../../utils/doRequest'

const traceInstance = getInstance('/v1/trace')

const commonInstance = getInstance('');

/**
 * 我的交易员列表
 * @param ctx
 */
export const myTraders = data => {
  return traceInstance.post('/tracer/myTraders', data);
}

/**
 * 跟单全局配置信息
 * 在跟单 止盈止损时 需要用到
 * @param ctx
 * @returns
 *   traceLeverage  number 跟单杠杠
 *   defaultTraceRation  跟单比例
 * defaultTraceCount  跟单张数
 * minTraceRation 最小跟单比例
 * maxTraceRation 最大跟单比例
 * minTraceCount 最小跟单张数
 * maxTraceCount 最大跟单张数
 * maxHoldCount 最大持仓张数
 * minStopProfitRation 最小止盈比例
 * maxStopProfitRation 最大止盈比例
 * sliderMaxStopProfitRatio 滑竿最大止盈比例
 * minStopLossRation 最小止损比例
 * maxStopLossRation 最大止损比例
 * sliderMaxStopLossRatio 滑竿最大止损比例
 * openCurrentOrderStatus  当前带单是否展示  0是 1 否
 */
export const traceGlobalConfig = data => {
  return traceInstance.post('/public/globalConfig', data);
}


/**
 * 登录人信息
 * @param ctx
 */
export const getTraceUserInfo = data => {
  return traceInstance.post('/user/getTraceUserInfo', data);
}

/**
 * 交易员列表
 * @param ctx
 */
export const traderListView = cacheApiFunc(data => {
  return traceInstance.post('/public/traderListView', data);
})


/**
 * 交易员账户主页
 * @param ctx
 */
export const traderDetailPage = data => {
  return traceInstance.post('/trader/traderDetailPage', data);
}

/**
 * 我的跟随者列表(正在跟的跟随者)
 * @param ctx
 */
export const myTracers = data => {
  return traceInstance.post('/trader/myTracers', data);
}

/**
 * 交易员移除跟随者
 * @param ctx
 */
export const removeTracer = data => {
  return traceInstance.post('/trader/removeTracer', data);
}

/**
 * 收益明细(统计)
 * @param ctx
 */
export const profitList = data => {
  return traceInstance.post('/trader/profitList', data);
}

/**
 * 历史分润(统计)
 * @param ctx
 */
export const profitHisList = data => {
  return traceInstance.post('/trader/profitHisList', data);
}

/**
 * 历史分润明细列表
 * @param ctx
 */
export const profitHisDetailList = data => {
  return traceInstance.post('/trader/profitHisDetailList', data);
}

/**
 * 待分润明细列表
 * @param ctx
 */
export const waitProfitDetailList = data => {
  return traceInstance.post('/trader/waitProfitDetailList', data);
}

/**
 * 累积已分润列表
 * @param ctx
 */
export const totalProfitList = data => {
  return traceInstance.post('/trader/totalProfitList', data);
}

/**
 * 跟随者（已经在跟和跟过的）
 * @param ctx
 */
export const followerList = data => {
  return traceInstance.post('/trader/followerList', data);
}

/**
 * 我的（历史带单/历史跟单）
 * @param ctx
 */
export const historyList = data => {
  return traceInstance.post('/order/historyList', data);
}

/**
 * 我的交易员
 * @param ctx
 */
export const myBatchTrader = data => {
  return traceInstance.post('/tracer/myBatchTrader', data);
}

/**
 * 取消跟随
 * @param ctx
 */
export const removeTrader = data => {
  return traceInstance.post('/tracer/removeTrader', data);
}

/**
 * 跟单设置
 * @param ctx
 */
export const setBatchTraceConfig = data => {
  return traceInstance.post('/user/setBatchTraceConfig', data);
}

/**
 * 个人设置信息
 * @param ctx
 */
export const getTraceProfile = data => {
  return traceInstance.post('/user/getTraceProfile', data);
}

/**
 * 申请交易员
 * @param ctx
 */
export const applyTrader = data => {
  return traceInstance.post('/trader/applyTrader', data);
}

/**
 * 检查交易员审核状态
 * @param ctx
 */
export const queryApplyTraderStatus = data => {
  return traceInstance.post('/trader/queryApplyTraderStatus', data);
}

/**
 * 设置昵称(多语言)
 * @param ctx
 */
export const setEnNickName = data => {
  return traceInstance.post('/user/setEnNickName', data);
}

/**
 * 设置简介（多语言）
 * @param ctx
 */
export const setEnProfile = data => {
  return traceInstance.post('/user/setEnProfile', data);
}

/**
 * 设置带单合约
 * @param ctx
 */
export const setProductCode = data => {
  return traceInstance.post('/user/setProductCode', data);
}

/**
 * 自动取消跟随
 * @param ctx
 */
export const setAutoCancel = data => {
  return traceInstance.post('/user/setAutoCancel', data);
}

/**
 * 空位提醒
 * @param ctx
 */
export const setRemindTrace = data => {
  return traceInstance.post('/tracer/setRemindTrace', data);
}

/**
 * 交易员筛选条件列表
 * @param ctx
 */
export const sortConditionList = data => {
  return traceInstance.post('/public/sortConditionList', data);
}

/**
 * 全局配置接口
 * @param ctx
 */
export const globalConfig = data => {
  return traceInstance.post('/public/globalConfig', data);
}

/**
 * 交易员开启的交易对的合约列表的信息
 * @param ctx
 */
export const getUserTraceLevelSetting = data => {
  return traceInstance.post('/tracer/getUserTraceLevelSetting', data);
}

/**
 * 当前带单查询
 * @param ctx
 */
export const currentList = data => {
  return traceInstance.post('/order/currentList', data);
}

/**
 * 交易员设置是否可以但查看跟单 ??? 接口貌似不存在了
 * @param ctx
 */
export const setCurOrder = data => {
  return traceInstance.post('/trader/setCurOrder', data);
}

/**
 * 查询用户当前币对是否跟随
 * @param ctx
 */
export const getFollowByProductCode = data => {
  return traceInstance.post('/tracer/getFollowByProductCode', data);
}

/**
 * 获取多语言的设置审核信息
 * @param ctx
 */
export const getMultiLanguage = data => {
  return traceInstance.post('/user/getMultiLanguage', data);
}

/**
 * 消息推送
 * @param ctx
 */
export const pushList = data => {
  return commonInstance.post('/v1/if/push/list', data);
}

/**
 * 我的交易员
 * @param {*} data
 */
export const myTrader = data => {
  return traceInstance.post('/tracer/myTrader', data);
}

/**
 * 获取个人信息
 * @param {*} data
 */
export const traceProfile = data => {
  return traceInstance.post('/user/traceProfile', data);
}


export const filesUpload = data => {
  return traceInstance.post('/files/upload', data);
}

/**
 * 跟单信息
 * @param {*} data
 * @returns
 */
export const traderContractInfoList = data => {
  return traceInstance.post('/trader/traderContractInfoList', data);
}

/**
 * 带单信息
 * @param {*} data
 * @returns
 */
export const traceContractInfoList = data => {
  return traceInstance.post('/tracer/traceContractInfoList', data);
}

/**
 * 邀请跟随者
 * @param ctx
 */
 export const traderInvite = data => {
  return traceInstance.post('/trader/invite/followers', data);
 }

 /**
 *资管员成績信息
 * @param {*} data
 * @returns
 */
export const traderView = data => {
  return traceInstance.post('/public/score/traderView', data);
}

/**
 * 同意/拒绝 资管员跟单邀请
 * @param {*} data
 * @returns
 */
export const setAssetTradersFollowStatus = data => {
  return traceInstance.post('/tracer/setAssetTradersFollowStatus', data);
}
