import { ABANDONED } from '../config';
import { isClient } from '~/utils/envHelper';
const newParams = {
  languageType: 0,
  sign: 'SIGN',
  timeZone: 'string',
  positionId: 'string',
  autoAppendMargin: true
};

// contract/modifyAutoAppendMargin //设置自动添加保证金
const contractModifyAutoAppendMargin = {
  productCode: '',
  afterAppendType: '', // 追加保证金类型 0 不自动追加 1 自动追加
  side: '', // 方向 1多仓 2空仓
  openOrderNo: '' // 分仓仓位的开仓订单编号
}; // 返回时有个特殊的 code -> 'product_not_support_auto_append_margin'

const paramsKeyMap = {
  afterAppendType: 'autoAppendMargin',
  positionId: 'positionId', // 新增 逐仓仓位id
  productCode: ABANDONED,
  side: ABANDONED,
  openOrderNo: ABANDONED
};
// 只有逐仓模式才能使用
const updateAutoAppendMarginAdapter = {
  req: data => {
    const params = {};
    if (isClient) {
      const paramsList = window.Object.entries(data);
      paramsList.forEach(([key, value]) => {
        switch (true) {
          case key === 'afterAppendType':
            params.autoAppendMargin = Boolean(value); // 0 - false 1 -true
            break;
          default:
            if (paramsKeyMap[key] !== ABANDONED) {
              params[paramsKeyMap[key] || key] = value;
            }
        }
      });
    }
    return params;
  },

  res: res => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }
};

export default updateAutoAppendMarginAdapter;
