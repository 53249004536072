// 前端 统一 server 端服务封装

import { cacheApiFunc } from '~/utils/cacheApi/index';
import { getInstance } from '../../utils/doRequest';
const commonInstance = getInstance('');

// 币币资产
export const getSpotAssets = data => {
  return commonInstance.post('/v1/spot/assets', data);
};

// 单个币种资产
export const getSpotCoinAssets = data => {
  return commonInstance.post('/v1/spot/getAssets', data);
};

// 获取币种信息及链信息列表
// export const getSpotCoinChainList = cacheApiFunc(data => {
//   return commonInstance.post('/v1/spot/public/coinChainList', data);
// });
export const getSpotCoinChainList = data => {
  return commonInstance.post('/v1/spot/public/coinChainList', data);
};

// 获取币币账单
export const getSpotBillList = data => {
  return commonInstance.post('/v1/spot/billList', data);
};
// 添加提币地址
export const addSpotWithdrawAddr = data => {
  return commonInstance.post('/v1/spot/addWithdrawAddr', data);
};
// 获取提币地址列表
export const getSpotWithdrawAddrList = data => {
  return commonInstance.post('/v1/spot/withdrawAddrList', data);
};
// 删除提币地址
export const delSpotWithdrawAddr = data => {
  return commonInstance.get('/v1/spot/delWithdrawAddr', data);
};
// 校验提币地址是否存在
export const checkSpotWithdrawAddr = data => {
  return commonInstance.post('/v1/spot/checkWithdrawAddr', data);
};
// 获取币种充值的地址
export const getSpotRechargeAddress = data => {
  // return commonInstance.post('/v1/spot/rechargeAddress', data)
  let timeStamp = new Date().getTime();
  return commonInstance.post('/v1/spot/rechargeAddress?t=' + timeStamp, data);
};
// 点击按钮获取充币地址
export const getSpotNewRechargeAddress = data => {
  // return commonInstance.post('/v1/spot/rechargeAddress', data)
  let timeStamp = new Date().getTime();
  return commonInstance.post('/v1/spot/rechargeAddress/new?t=' + timeStamp, data);
};
// 获取充币提币订单列表
export const getSpotCapitalOrderList = data => {
  return commonInstance.post('/v1/spot/capitalOrderList', data);
};

// 充币 提币 详情
export const getSpotCapitalOrderInfo = data => {
  return commonInstance.post('/v1/spot/capitalOrderInfo', data);
};

// 提币限额信息
export const getSpotLimitInfo = data => {
  return commonInstance.post('/v1/spot/getLimitInfo', data);
};

// 提币前置检查接口
export const checkPreWithdraw = data => {
  return commonInstance.post('/v1/spot/checkPreWithdraw', data);
};
// 发起提币
export const doSpotWithdraw = data => {
  return commonInstance.post('/v1/spot/withdraw', data);
};

// 取消提币
export const cacelSpotWithdraw = data => {
  return commonInstance.post('/v1/spot/cancelWithdraw', data);
};

// 取消提币-兼容老数据
export const cacelOldSpotWithdraw = data => {
  return commonInstance.post('/v1/spot/withdraw/cancel', data);
};

// 获取各账户下的资产信息
export const getSpotAllAsset = data => {
  return commonInstance.post('/v1/gw/assets', data);
};

export const getSpotAllAssetNew = data => {
  return commonInstance.post('/v1/gw/assets/new', data);
};

// 获取各账户下的资产信息
export const getAssetsWithBalance = data => {
  return commonInstance.post('/v1/gw/assetsWithBalance', data);
};

export const getAssetsWithBalanceNew = data => {
  return commonInstance.post('/v1/gw/assetsWithBalance/new', data);
};
// export const getAssetsWithBalanceNew = cacheApiFunc(data => {
//   return commonInstance.post('/v1/gw/assetsWithBalance/new', data);
// });

// 币币账单下查询选项
export const getUserGroupTypeList = data => {
  return commonInstance.post('/v1/spot/getUserGroupTypeList', data);
};

// bft 抵扣开关
export const switchDeduct = data => {
  return commonInstance.post('/v1/spot/switchDeduct', data);
};

// 合约Pro查询体验金单独接口
export const getAssetsCashGift = data => {
  return commonInstance.post('/v1/gw/assetsCashGiftBalance', data);
};
