// 前端 统一 server 端服务封装
import { cacheApiFunc } from '~/utils/cacheApi/index';
import { getInstance } from '../../utils/doRequest';

const commonInstance = getInstance('');

//合约费率展示 活动费率 展示区
export const listActivityFeeConfig = data => {
  return commonInstance.post('/v1/user/public/listActivityFeeConfig', data);
};

// 合约费率展示 活动费率 交易区
export const getFeeConfigByUserId = data => {
  return commonInstance.post('/v1/user/public/getFeeConfigByUserId', data);
};

// 合约费率展示 海外手续费率 交易区
export const getFeeRate = data => {
  return commonInstance.post('/v1/if/contract/getFeeRate', data);
};

// 新手活动
export const getTaskRequirements = data => {
  return commonInstance.get('/v1/activity/beginnerTask/taskRequirements', data);
};

// 活动列表
// export const getTaskList = cacheApiFunc(data => {
//   return commonInstance.get('/v1/activity/list', data);
// });
export const getTaskList = data => {
  return commonInstance.get('/v1/activity/list', data);
};
// 活动所有列表
export const getTaskAllList = data => {
  return commonInstance.get('/v1/activity/allList', data);
};

// 活动详情
export const getTaskDetail = data => {
  return commonInstance.get('/v1/activity/detail', data);
};
// 我的wxt任务详情
export const getMySubscribeInfo = data => {
  return commonInstance.get('/v1/activity/subscribe/mySubscribeInfo', data);
};

// 认购支付
export const postSubscribePay = data => {
  return commonInstance.post('/v1/activity/subscribe/subscribePay', data);
};
// wxt认购排行榜-已登录
export const getLeaderboard = data => {
  return commonInstance.get('/v1/activity/subscribe/leaderboard', data);
};
// wxt未登录认购排行榜
export const getPublicLeaderboard = data => {
  return commonInstance.get('/v1/activity/subscribe/public/leaderboard', data);
};
// 我的积分明细
export const getMyIntegralInfo = data => {
  return commonInstance.get('/v1/activity/subscribe/myIntegralInfo', data);
};
// 我的领取记录
export const getRewardList = data => {
  return commonInstance.get('/v1/activity/getRewardList', data);
};

// 未登录查询活动详情
export const getTaskNoLoginDetail = data => {
  return commonInstance.get('/v1/activity/noLoginDetail', data);
};

// 获取奖励数量
export const getRewardNum = data => {
  return commonInstance.get(`/v1/activity/totalBonusSchedule`, data);
};

// 活动报名
export const getTaskApply = (id,data ={}) => {
  return commonInstance.post(`/v1/activity/apply?activityId=${id}`,data);
};

// 活动报名状态
export const getTaskApplyStatus = (id,data ={}) => {
  return commonInstance.post(`/v1/activity/applyStatus?activityId=${id}`,data);
};

// 活动任务完成情况
export const getTaskCompletions = (id,data ={}) => {
  return commonInstance.post(`/v1/activity/beginnerTask/taskCompletions?activityId=${id}`,data);
};

// 活动任务交易排行榜
export const getTaskLeaderboard = data => {
  return commonInstance.get(`/v1/activity/tradingCompetition/leaderboard`, data);
};

// KYC活动任务完成情况
export const getTaskUserKycRecord = (id,data) => {
  return commonInstance.post(`/v1/activity/beginnerTask/userKycRecord?activityId=${id}`,data);
};

// 领取奖励
export const getTaskReward = (data,dataObj) => {
  return commonInstance.post(`/v1/activity/obtainReward?activityId=${data.activityId}&taskId=${data.taskId}`,dataObj);
};

// 最近10条获奖轮询
export const getRecentRewards = data => {
  return commonInstance.get(`/v1/activity/beginnerTask/recentRewards`, data);
};

// 提交联系方式 - 阳光普照
export const submitContact = (id, data) => {
  return commonInstance.post(`/v1/activity/tradingCompetition/submitContact?activityId=${id}`, data);
};

// 是否已提交联系方式 - 阳光普照
export const contactSubmitted = id => {
  return commonInstance.post(`/v1/activity/tradingCompetition/contactSubmitted?activityId=${id}`);
};

// 我的交易额
export const myTradingVolume = id => {
  return commonInstance.post(`/v1/activity/tradingCompetition/myTradingVolume?activityId=${id}`);
};

// wxt锁仓列表
export const wxtUnlockList = data => {
  return commonInstance.get(`/v1/spot/unLockList`,data);
};

// 获取浮窗广告
export const getFloatActivity = data => {
  return commonInstance.get(`/v1/mix/public/getOnlineFloatingWindow`,data);
};
// export const getFloatActivity = cacheApiFunc(data => {
//   return commonInstance.get(`/v1/mix/public/getOnlineFloatingWindow`,data);
// });
