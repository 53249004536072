const accountAdapter = {
  req: data => {
    const params = {
      languageType: 1,
      sign: 'SIGN',
      timeZone: 'string'
    };
    return { ...params, ...data };
  },

  // res: res => {
  //   return new Promise((resolve, reject) => {
  //     const {
  //       code,
  //       data,
  //       responseTime
  //     } = res;

  //     const result = {
  //       code,
  //       data,
  //       restime: responseTime
  //     };

  //     resolve(result);
  //   });
  // }

  res: res => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }
};

export default accountAdapter;
